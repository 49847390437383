import {API_BASE_URL} from "../../../config/constants";
import { postRequest } from '../../API'
/*********************************************************
* Function Name : userList
* Description   : Get list of users list
* By            : Afsar Ali
* Date          : 12-11-2024 
*********************************************************/
export const userList = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}front/v1/loadbalance/users-list`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : list
* Description   : Get list of invoice
* By            : Afsar Ali
* Date          : 19-11-2024 
*********************************************************/
export const list = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}v1/admin/invoices/list`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result, count: res?.data?.response?.count,};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : chnageStatus
* Description   : chnage service status
* By            : Afsar Ali
* Date          : 04-03-2024 
*********************************************************/
export const changeStatus = async (options) =>{
    try {
        const { url, postData={} } = options;
        const params = {
            url : `${API_BASE_URL}${url}`,
            postData : {
                ...postData,
                ipAddress : sessionStorage.getItem('IP_ADDRESS')
            }
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : getLoadBalanceData
* Description   : Get list of users list
* By            : Afsar Ali
* Date          : 12-11-2024 
*********************************************************/
export const getLoadBalanceData = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}front/v1/loadbalance/list`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : generateInvoice
* Description   : generateInvoice
* By            : Afsar Ali
* Date          : 19-11-2024 
*********************************************************/
export const createInvoice = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}v1/admin/invoices/create`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : updateInvoice
* Description   : updateInvoice
* By            : Afsar Ali
* Date          : 23-11-2024 
*********************************************************/
export const updateInvoice = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}v1/admin/invoices/update`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : deleteInvoice
* Description   : Delete invoice
* By            : Afsar Ali
* Date          : 20-11-2024 
*********************************************************/
export const deleteInvoice = async (id) =>{
    try {
        const params = {
            url : `${API_BASE_URL}v1/admin/invoices/delete/${id}`,
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : createRequest
* Description   : create request
* By            : Afsar Ali
* Date          : 20-11-2024 
*********************************************************/
export const createRequest = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}v1/admin/invoices/create-request`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : createRequest
* Description   : create request
* By            : Afsar Ali
* Date          : 20-11-2024 
*********************************************************/
export const requestList = async (options) =>{
    try {
        const params = {
            url : `${API_BASE_URL}v1/admin/invoices/request-list`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result, count: res?.data?.response?.count,};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : deleteInvoice
* Description   : Delete invoice
* By            : Afsar Ali
* Date          : 20-11-2024 
*********************************************************/
export const deleteRequest = async (id) =>{
    try {
        const params = {
            url : `${API_BASE_URL}v1/admin/invoices/delete-request/${id}`,
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : requestChangeStatus
* Description   : Change request status
* By            : Afsar Ali
* Date          : 27-11-2024 
*********************************************************/
export const requestChangeStatus = async (id) =>{
    try {
        const params = {
            url : `${API_BASE_URL}v1/admin/invoices/change-status-request/${id}`,
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintenance, Please try after some time."}
    }
};//End of Function



