import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import html2pdf from "html2pdf.js";

import {
    list
  } from "../../../../controllers/V1/invoice/invoiceControllers";
import PreviewInvoice from '../preview';
const Invoice = () => {
    const { batch_id } = useParams();
    const printRef = useRef();

    const [isLoading, setIsLoading] =useState(true);
    const [ALLLISTDATA, setListData] = useState([]);

    /*********************************************************
   *  This function is use to fetch vendor list
   *********************************************************/
  const getList = async () => {
    try {
      setIsLoading(true);
      setListData([]);
      const options = {
        type: "",
        condition: {
          batch_no : batch_id, status : 'Completed'
        },
        select: {},
        sort: { _id: -1 },
      };
      const listData = await list(options);
      if (listData.status === true) {
        if (listData.result.length > 0) {
          setListData(listData?.result);
        } else {
          setListData([]);
        }
      } else {
        setListData([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }; //End

    const generatePdf = async () => {
        const element = printRef.current;
        const options = {
            margin      : .25,
            filename    : "example.pdf",
            image       : { type: "jpeg", quality: 1 },
            html2canvas : { dpi: 1200, letterRendering: true, scale: 3 },
            jsPDF       : { unit: "in", format: [8.5, 11], orientation: "portrait" }
        };
        html2pdf().from(element).set(options).save();
    };

    useEffect(()=>{
        getList();
    },[]);
    
  return (
  <div ref={printRef}>
    {ALLLISTDATA?.length > 0 && (
        ALLLISTDATA.map((items, index) => (<>
            <PreviewInvoice nextInvoice={false} invoiceData={items}/>
            <br/>
        </>)))}
  </div>);
};

export default Invoice;
