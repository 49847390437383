import React, { useEffect, useState } from "react";
import { Upload, Flex, Radio, notification, InputNumber, Select } from "antd";
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
// import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate } from "react-router-dom"

import {
  addeditdata,
  getPermission
} from "../../../controllers/subAdmin/subAdminControllers";
import { fetchIpAddress } from "../../../controllers/API";

import LoadingEffect from "../../../components/Loading/LoadingEffect";
import { beforeUpload } from "../../../controllers/common";

const UserListEditPage = () => {
  const { Option } = Select;
  const { Dragger } = Upload;
  const loginUserData = JSON.parse(sessionStorage.getItem("ADMIN-INFO"));
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [error, setError] = useState([]);
  const [ADDEDITDATA, setAddEditSata] = useState(state ? state : []);

  const [isLoading, setIsLoading] = useState(false);

  /***************  Define Permission States ***************/
  const [assignPermission, setAssignPermission] = useState('');
  const [invoicePermission, setInvoicePermission] = useState({ 
    individual: 'N',
    bulk_request: 'N',
  });

  const [customerDuePermission, setCustomerDuePermission] = useState({ 
    customer_list: 'N'
  });

  /*********************** End ************************ */
  /*********************************************************
   *  This function is use to handle imput chnage
   *********************************************************/
  const handleChange = (e) => {
    setAddEditSata((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setError((pre) => ({
      ...pre,
      [e.target.name]: "",
    }));
  };
  /*********************************************************
   *  This function is used to handle phone number change
   *********************************************************/
  const handlePhoneChange = (value) => {
    setAddEditSata((prev) => ({
      ...prev,
      admin_phone: value,
    }));
    setError((prev) => ({
      ...prev,
      admin_phone: "", // Clear previous error message
    }));
  };
  /*********************************************************
   *  This function is used to handle phone code change
   *********************************************************/
  const handlePhoneCodeChange = (value) => {
    setAddEditSata((prev) => ({
      ...prev,
      vendor_country_code: value,
      vendor_phone: "", // Clear phone number
    }));
    setError((prev) => ({
      ...prev,
      vendor_phone: "", // Clear previous error message
    }));
  };

  /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const ipAddress = await fetchIpAddress();
      const form = new FormData(event.target);
      if (ADDEDITDATA?._id && ADDEDITDATA?._id !== "undefined" && ADDEDITDATA?._id !== null) {
        form.append("edit_id", ADDEDITDATA?._id);
      }
      form.append('admin_phone', ADDEDITDATA?.admin_phone);
      form.append("ipAddress", ipAddress);
      const isValidate = validateFormData(form);
      const permissions = {
        customer_due: customerDuePermission,
        invoice: invoicePermission
      }
      if (isValidate) {
        const rowData = {};
        form.forEach((value, key) => {
          rowData[key] = value;
        });
        const options = {
          url: `users/create-sub-admin/${ADDEDITDATA?._id ? ADDEDITDATA?._id : ':editId'}`,
          postData: { ...rowData, permissions },
        };

        const res = await addeditdata(options);
        if (res.status === true) {
          notification.open({
            message: "Data updated.",
            description: `Data updated Successfully.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          navigate("/sub-admin/list");
        } else {
          notification.open({
            message: "Oops!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      } else {
        notification.open({
          message: "Oops!",
          description: `Please enter the required fields.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  /*********************************************************
   *  This function is used to validate form data before submit
   *********************************************************/
  const validateFormData = (formData) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const vendor_country_code = '+971';
    if (!formData.get("admin_first_name")?.trim()) {
      setError((prevError) => ({
        ...prevError,
        admin_first_name: "First name is required",
      }));
      return false;
    }
    if (!formData.get("admin_last_name")?.trim()) {
      setError((prevError) => ({
        ...prevError,
        admin_last_name: "Last name is required",
      }));
      return false;
    }
    if (!formData.get("admin_email")?.trim()) {
      setError((prevError) => ({
        ...prevError,
        admin_email: "Email address is required",
      }));
      return false;
    }
    if (!emailPattern.test(formData.get("admin_email"))) {
      setError((prevError) => ({
        ...prevError,
        admin_email: "Please enter a valid email address",
      }));
      return false;
    }
    const phone = formData.get("admin_phone");
    if (
      !phone?.trim() ||
      (vendor_country_code === "+91" && phone.length !== 10) ||
      (vendor_country_code === "+971" && phone.length !== 9)
    ) {
      setError((prevError) => ({
        ...prevError,
        admin_phone: !phone
          ? "Phone number is required"
          : `Phone number must be exactly ${vendor_country_code === "+91" ? 10 : 9
          } digits for ${vendor_country_code === "+91" ? "India" : "UAE"}`,
      }));
      return false;
    }

    return true;
  };

  const getUserpermission = async () => {
    try {
      setIsLoading(true);
      const option = { admin_id: ADDEDITDATA?._id }
      const res = await getPermission(option);
      if (res.status === true && res.result !== 'undefined') {
        setIsLoading(false);

        setInvoicePermission(res.result?.invoice)
        setCustomerDuePermission(res.result?.customer_due)
      } else {
        setAssignPermission([])
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (ADDEDITDATA?._id) {
      getUserpermission();
    }
    document.title = `U-Winn CRM Admin || ${ADDEDITDATA?._id ? "Edit" : "Add"
      } Sub Admin`;
  }, []);

  /*********************************************************
*  This function is used to handle permissions dynamically
*********************************************************/
  const handlePermission = (type, value) => {
    try {
      console.log('type',type); //customer_due
      console.log('value',value); // customer_list
      if (type === "invoice") {
        setInvoicePermission((prev={}) => ({
          ...prev,
          [value]: prev[value] === "Y" ? "N" : "Y",
        }));
      } else if(type === 'customer_due'){
        setCustomerDuePermission((prev={}) => ({
          ...prev,
          customer_list : prev[value] === "Y" ? "N" : "Y",
        }));
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="Inventory_edit_page_main_div">
          <React.Fragment>
            <h5 className="inventory_edit_page_heading"> {ADDEDITDATA?._id ? `Edit` : `Add`} Sub Admin </h5>
          </React.Fragment>
          <Link to={ADDEDITDATA.admin_type === 'Super Admin'?"/sub-admin/list":"/dashboard"}>
            <React.Fragment>
              <button type="button" className="btn btn-secondary"> <ArrowLeftOutlined /> Back </button>
            </React.Fragment>
          </Link>
        </div>

        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-body">
            <div className="responsive">
              <form method="post" className="inventory_form_main_contnet" onSubmit={handleSubmit}>
                <div className="row ">
                  <div className="row">
                    <div className="form-group col-lg-4 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="admin_first_name"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>First Name :{" "}
                      </label>
                      <input
                        title="Enter your first name."
                        placeholder="Enter your first name."
                        type="text"
                        name="admin_first_name"
                        className="form-control store_input_field"
                        id="admin_first_name"
                        value={ADDEDITDATA?.admin_first_name}
                        onChange={handleChange}
                      />
                      {error.admin_first_name ? (
                        <p style={{ color: "red" }}>{error.admin_first_name}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-4 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="admin_last_name"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>Last Name :{" "}
                      </label>
                      <input
                        title="Enter your last name."
                        placeholder="Enter your last name."
                        type="text"
                        name="admin_last_name"
                        className="form-control store_input_field"
                        id="admin_last_name"
                        value={ADDEDITDATA?.admin_last_name}
                        onChange={handleChange}
                      />
                      {error.admin_last_name ? (
                        <p style={{ color: "red" }}>{error.admin_last_name}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-4 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="admin_email"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Email Address :
                      </label>
                      <input
                        title="Enter your email address."
                        placeholder="Enter your email address."
                        type="text"
                        name="admin_email"
                        className="form-control store_input_field"
                        id="admin_email"
                        value={ADDEDITDATA?.admin_email}
                        readOnly={ADDEDITDATA?._id ? true : false}
                        onChange={handleChange}
                      />
                      {error.admin_email ? (
                        <p style={{ color: "red" }}>{error.admin_email}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row">
                    {/* Second row: Phone Number and Department */}
                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="admin_phone"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Phone Number:
                      </label>
                      <br />
                      <InputNumber
                        style={{ width: "100%" }}
                        onChange={handlePhoneChange}
                        addonBefore={
                          <Select
                            defaultValue={"+971"}
                            style={{ width: 150 }}
                            onChange={handlePhoneCodeChange}
                          >
                            <Option value="+971" selected={true}>
                              +971 (UAE)
                            </Option>
                          </Select>
                        }
                        value={ADDEDITDATA?.admin_phone}
                      />
                      {error.admin_phone ? (
                        <p style={{ color: "red" }}>{error.admin_phone}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="department_name"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>Department Name :
                      </label>
                      <input
                        title="Enter department name."
                        placeholder="Enter department name."
                        type="text"
                        name="department_name"
                        className="form-control store_input_field"
                        id="department_name"
                        value={ADDEDITDATA?.department_name}
                        onChange={handleChange}
                      />
                      {error.department_name ? (
                        <p style={{ color: "red" }}>{error.department_name}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {(loginUserData.admin_type === 'Super Admin' && ADDEDITDATA.admin_type !== 'Super Admin' ) && (
                    <div className="row">
                      <label> Permission: </label>
                      {/* Customer's Due */}
                      <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> Customer's Due : </label>
                        <Flex vertical gap="middle" className="">
                          <div className="verndor_dashboard_add_edit_button" size="large" >
                            <div className="row">
                              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                                onClick={() => handlePermission('customer_due', 'customer_list')}
                              >
                                <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="customer_list">Customer's list</label>
                                  <input
                                    type="checkbox"
                                    id="customer_list"
                                    name="customer_list"
                                    value="Y"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={customerDuePermission?.customer_list === "Y" ? true : false}
                                  />
                                </div>
                              </div>

                            </div>
                          </div>
                        </Flex>
                      </div>

                      <div className="from-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label htmlFor="Accounts" className="all_top_and_bottom_for_input_text"> Invoice : </label>
                        <Flex vertical gap="middle" className="">
                          <div className="verndor_dashboard_add_edit_button" defaultValue="Y" name="in_store" id="in_store" size="large" >
                            <div className="row">
                              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                                onClick={() => handlePermission('invoice', 'individual')}
                              >
                                <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="individual">Individual</label>
                                  <input
                                    type="checkbox"
                                    id="individual"
                                    name="individual"
                                    value="Y"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={invoicePermission?.individual === "Y" ? true : false}
                                  />
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12"
                                onClick={() => handlePermission('invoice', 'bulk_request')}
                              >
                                <div className="vendor_dashbaord_gender_main_section">
                                  <label htmlFor="bulk_request">Bulk Request</label>
                                  <input
                                    type="checkbox"
                                    id="bulk_request"
                                    name="bulk_request"
                                    value="Y"
                                    className="form-check-input"
                                    disabled={true}
                                    checked={invoicePermission?.bulk_request === "Y" ? true : false}
                                  />
                                </div>
                              </div>

                            </div>
                          </div>
                        </Flex>
                      </div>

                    </div>
                  )}
                </div>  
                <div className="inventory_edit_submit_btn">
                  <button type="submit" className="btn btn-primary inventory_save_button" > Save </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <LoadingEffect isLoading={isLoading} />
    </div>
  );
};

export default UserListEditPage;
