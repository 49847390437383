import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, notification, Drawer, Button, Space } from "antd";
import { faFilter, faEdit, faEye, faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import Pagination from "@mui/material/Pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import TobNavBar from "../../../DashBaord/Top_navbar";
// import VendorListDeletePopUp from "./DeletePopUp";
import { Link, useNavigate, useParams } from "react-router-dom";
import {formatProjectDate} from "../../../../controllers/common";
import { InfoCircleOutlined, CheckCircleOutlined, LikeOutlined, DislikeOutlined,} from "@ant-design/icons";
import moment from "moment";
import SkeltonList from "../../../../components/SkeltonEffect/list";
import {
  list,
  deleteRequest,
  getLoadBalanceData,
  updateInvoice,
  requestChangeStatus
} from "../../../../controllers/V1/invoice/invoiceControllers";
import { list as dueList} from '../../../../controllers/V1/customerDue/dueController';
import { getPage, getRelativeTime } from "../../../../controllers/common";
import {API_BASE_URL} from "../../../../config/constants";
function VendorList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

VendorList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  /************************************************************************************* */
  const [isLoading, setIsLoading] =useState(true);
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const {batch_id}= useParams();
  const [ALLLISTDATA, setListData] = useState([]);

  const [pendingInvoice, setPendingInvoice] = useState([]);
  const [pendingInvoiceCount, setPendingInvoiceCount] = useState(0);
 
  const handleEdit = async (item = {}) => {
    navigate("/invoice/create-request", { state: item });
  };
  /*********************************************************
   *  This function is use to fetch vendor list
   *********************************************************/
  const getList = async () => {
    try {
      // console.log('batch_id',batch_id);
      setIsLoading(true);
      setListData([]);
      const options = {
        type: "",
        condition: {
          batch_no : batch_id
        },
        select: {},
        sort: { _id: -1 },
      };
      const listData = await list(options);
      if (listData.status === true) {
        if (listData.result.length > 0) {
          setListData(listData?.result);
          let updatedRetailerList = listData?.result.filter((retailer) => retailer?.status === 'Pending');
          // let updatedRetailerList = listData?.result.filter((retailer) => retailer?.status === 'Completed');
          setPendingInvoice(updatedRetailerList);
          setPendingInvoiceCount(updatedRetailerList?.length); 
          // generateInvoice(listData?.result[0])

        } else {
          setListData([]);
        }
      } else {
        setListData([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }; //End
 

  /*********************************************************
   *  This function is use to handle remove invoice
   *********************************************************/
  const handleRemove = async (id='') => {
    try {
      if(!id){
        notification.open({
          message: "Oops!",
          description: `Invoice id is required.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }else{
        const res = await deleteRequest(id);
        if (res.status === true) {
          notification.open({
            message: "Success",
            description: `Data deleted successfully.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
        } else {
          notification.open({
            message: "Oops!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      }
    } catch (error) {
      notification.open({
        message: "Oops!",
        description: `Operation not perform yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
    getList();
  };
  
  /*********************************************************
   *  This function will load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior:'smooth',
    });
    document.title = "CRM U-Winn || Invoice Generate";
  }, []);
  
  const fetchUserDue = async (user_id) => {
    try {
      const option = {
        type : 'single',
        condition : { users_oid : user_id },
        select : {due_amount : true}
      }
      const res = await dueList(option);
      if(res.status === true){
        return res.result?.due_amount
        // setInvoiceData((pre)=>({
        //   ...pre,
        //   old_balance : due?.due_amount
        // }))
      } else{
        return 0;
        // setInvoiceData((pre)=>({
        //   ...pre,
        //   old_balance : 0
        // }))
      }
    } catch (error) {
      return 0;
    }
  }

  useEffect(()=>{
    if(pendingInvoiceCount > 0){
      generateInvoice(pendingInvoice[pendingInvoiceCount - 1]);
    } else {
      requestChangeStatus(batch_id);
    }
  },[pendingInvoiceCount]);


/*********************************************************
 *  This function is use to handle form submit
 *********************************************************/
const generateInvoice = async (data) => {
  try {
    if(!data.users_id){
      alert('Please select user');
    } else{
      setListData((prev) =>
        prev.map((item) =>
          item._id === data._id ? { ...item, status: 'Preparing' } : item
        )
      );

      const startDate = moment(data?.start_date).subtract(4, 'hours').format('YYYY-MM-DD');
      const endDate = moment(data?.end_date).subtract(4, 'hours').format('YYYY-MM-DD');

      const startTime = data?.start_time || '21:31:00';
      const endTime = data?.end_time || '21:30:00';

      // const startDate = data?.start_date; 
      // const endDate = data?.end_date;

      const options = {
        condition : {
          $or : [
            {user_id_deb : parseInt(data?.users_id)},
            {user_id_cred : parseInt(data?.users_id)}
          ],
          created_at : { $gte : `${startDate} ${startTime}`, $lte : `${endDate} ${endTime}` }
        }
      }
      const res = await getLoadBalanceData(options);
      if(res?.status === true){

         //Lotto
         let lottoTotalSalesAmount = 0;
         let lottoTotalCommissionAmount = 0;
         let lottoTotalPaidAmount = 0;
         //Raffle

         let raffleTotalSalesAmount = 0;
         let raffleTotalCommissionAmount = 0;
         let raffleTotalPaidAmount = 0;
         //U-Points
         
         let pointsTotalSalesAmount = 0;
         let pointsTotalCommissionAmount = 0;
         let pointsTotalInsensitiveAmount = 0;
         let pointsTotalPaidAmount = 0;

         let salesAmount = 0;
         let commissionAmount = 0;
         let paidAmount = 0;
         let insensitiveAmount = 0;
         
         let old_balance
         let totalDueAmount = 0;
         let subTotalDue = 0;
         let total_debit = 0;

        let status = 'Completed';
        let remarks = '';
        if(res?.result?.length > 0){

          const result = res?.result.reduce(
            (acc, item) => {
              //Lotto
                if (item.narration === "Order") {
                    acc.orderSum += item.upoints || 0;
                } else if (item.narration === "Order Cancalled") {
                    acc.cancelledSum += item.upoints || 0;
                } else if(item.narration === 'Commission'){
                    acc.commissionSum += parseFloat(item.upoints) || 0;
                } else if(item.narration === 'Commission Reverted'){
                    acc.commissionRevertedSum += parseFloat(item.upoints) || 0;
                } else if(item.narration === 'Redeem Prize'){
                  acc.paidSum += parseFloat(item.upoints) || 0;
                }

                //Raffle
                else if(item.narration === "Raffle Order"){
                  acc.raffleOrdSum += item.upoints || 0;
                } else if(item.narration === "Raffle Order Cancalled"){
                  acc.raffleCancelSum += item.upoints || 0;
                } else if(item.narration === "Raffle Commission"){
                  acc.raffleCommissionSum += item.upoints || 0;
                } else if(item.narration === "Raffle Commission Reverted"){
                  acc.raffleCommissionReverse += item.upoints || 0;
                } else if(item.narration === "Raffle Cash Prize Redeem"){
                  acc.rafflePaidSum += item.upoints || 0;
                }

                //U-Points
                else if((item.narration === "Recharge" || item.narration === "Recharge Coupon") && item.record_type === 'Debit'){
                  acc.pointsOrdSum += item.upoints || 0;
                } else if(item.narration === "Recharge Coupon Cancelled" || item.narration === "Reverse Recharge"){
                  acc.pointsCancelSum += item.upoints || 0;
                } else if(item.narration === "Recharge Commission"){
                  acc.pointsCommissionSum += item.upoints || 0;
                } else if(item.narration === "Referrel Commission"){
                  acc.pointsInsensitive += item.upoints || 0;
                } else if(item.narration === "Recharge Commission Reverted"){
                  acc.pointsCommissionReverse += item.upoints || 0;
                } else if(item.narration === "Cash Prize Redeem"){
                  acc.pointsPaidSum += item.upoints || 0;
                }
    
                return acc;
            },
            { 
              orderSum: 0, cancelledSum: 0, commissionSum : 0, commissionRevertedSum:0, paidSum : 0,
              raffleOrdSum : 0, raffleCancelSum : 0, raffleCommissionSum : 0, raffleCommissionReverse : 0, rafflePaidSum : 0, 
              pointsOrdSum : 0, pointsCancelSum : 0, pointsCommissionSum : 0, pointsCommissionReverse : 0, pointsPaidSum : 0, pointsInsensitive : 0
            }
          );

          // console.log('result', result);
          const totalSales = parseFloat(result.orderSum) - parseFloat(result.cancelledSum);
          const totalCommission = parseFloat(result.commissionSum) - parseFloat(result.commissionRevertedSum);
          //Lotto
          lottoTotalSalesAmount = totalSales;
          lottoTotalCommissionAmount = totalCommission;
          lottoTotalPaidAmount = result?.paidSum;
          //Raffle
          const totalRaffleSales = parseFloat(result.raffleOrdSum) - parseFloat(result.raffleCancelSum);
          const totalRaffleCommission = parseFloat(result.raffleCommissionSum) - parseFloat(result.raffleCommissionReverse);

          raffleTotalSalesAmount = totalRaffleSales;
          raffleTotalCommissionAmount = totalRaffleCommission;
          raffleTotalPaidAmount = result?.rafflePaidSum;
          //U-Points
          const totalPointsSales = parseFloat(result.pointsOrdSum) - parseFloat(result.pointsCancelSum);
          const totalPointsCommission = parseFloat(result.pointsCommissionSum) - parseFloat(result.pointsCommissionReverse);

          pointsTotalSalesAmount = totalPointsSales;
          pointsTotalCommissionAmount = totalPointsCommission;
          pointsTotalInsensitiveAmount = result.pointsInsensitive || 0;
          pointsTotalPaidAmount = result?.pointsPaidSum || 0;

          if(parseFloat(lottoTotalSalesAmount) > 0) salesAmount += parseFloat(lottoTotalSalesAmount);
          if(parseFloat(lottoTotalCommissionAmount) > 0) commissionAmount += parseFloat(lottoTotalCommissionAmount);
          if(parseFloat(lottoTotalPaidAmount) > 0) paidAmount += parseFloat(lottoTotalPaidAmount);


          if(parseFloat(raffleTotalSalesAmount) > 0) salesAmount += parseFloat(raffleTotalSalesAmount);
          if(parseFloat(raffleTotalCommissionAmount) > 0) commissionAmount += parseFloat(raffleTotalCommissionAmount);
          if(parseFloat(raffleTotalPaidAmount) > 0) paidAmount += parseFloat(raffleTotalPaidAmount);

          if(parseFloat(pointsTotalSalesAmount) > 0) salesAmount += parseFloat(pointsTotalSalesAmount);
          if(parseFloat(pointsTotalCommissionAmount) > 0) commissionAmount += parseFloat(pointsTotalCommissionAmount);
          if(parseFloat(pointsTotalInsensitiveAmount) > 0) insensitiveAmount += parseFloat(pointsTotalInsensitiveAmount);
          if(parseFloat(pointsTotalPaidAmount) > 0) paidAmount += parseFloat(pointsTotalPaidAmount);
          
          
          total_debit = commissionAmount + paidAmount;
          
          totalDueAmount += parseFloat(salesAmount) 
          totalDueAmount -= parseFloat(total_debit) 
          totalDueAmount -= parseFloat(insensitiveAmount);
          
          
          subTotalDue += parseFloat(totalDueAmount);

          old_balance = await fetchUserDue(data?.users_oid);
          subTotalDue += parseFloat(old_balance);

        } else {
          status = 'Fail';
          remarks = 'No sales found'
        }

        const params = {
          id : data._id,

          lottoTotalSales : lottoTotalSalesAmount,
          lottoTotalCommission : lottoTotalCommissionAmount,
          lottoPaidAmount : lottoTotalPaidAmount,


          raffleTotalSales : raffleTotalSalesAmount,
          raffleTotalCommission : raffleTotalCommissionAmount,
          rafflePaidAmount : raffleTotalPaidAmount,

          pointsTotalSales : pointsTotalSalesAmount, 
          pointsTotalCommission : pointsTotalCommissionAmount,
          pointsIncentives : pointsTotalInsensitiveAmount,
          pointsPaidAmount : pointsTotalPaidAmount, 
          
          total_sales : salesAmount,
          total_commission : commissionAmount,
          total_paid : paidAmount,
          total_due : totalDueAmount,
          old_pending_balance : old_balance || 0,
          sub_total_due : subTotalDue,
          status : status,
          remarks : remarks
        }
        const invoiceRes = await updateInvoice(params);
        
        
        setListData((prev) =>
          prev.map((item) =>
            item._id === data._id ? { 
              ...item, 

              lottoTotalSales : lottoTotalSalesAmount,
              lottoTotalCommission : lottoTotalCommissionAmount,
              lottoPaidAmount : lottoTotalPaidAmount,


              raffleTotalSales : raffleTotalSalesAmount,
              raffleTotalCommission : raffleTotalCommissionAmount,
              rafflePaidAmount : raffleTotalPaidAmount,

              pointsTotalSales : pointsTotalSalesAmount, 
              pointsTotalCommission : pointsTotalCommissionAmount,
              pointsIncentives : pointsTotalInsensitiveAmount,
              pointsPaidAmount : pointsTotalPaidAmount,

              total_sales : salesAmount,
              total_commission : commissionAmount,
              total_paid : paidAmount,
              total_due : totalDueAmount,
              old_pending_balance : old_balance || 0,
              sub_total_due : subTotalDue,
              status: params.status, 
              remarks : params.remarks } : item
          )
        );
        setPendingInvoiceCount(pendingInvoiceCount - 1);
      }
    }
  } catch (error) {
    console.log("error", error);
    // setIsLoading(false);
  }
};


const handlePDFDownload = async (invoiceData) => {
  try {  
    const {
      store,users_name,pos_number,users_mobile,sales_person_name,invoice_date,invoice_no,payment_mode,start_date,end_date,start_time,end_time,area,
      lottoTotalSales, lottoTotalCommission, lottoPaidAmount, lottoIncentives,
      raffleTotalSales, raffleTotalCommission, rafflePaidAmount, raffleIncentives,
      pointsTotalSales, pointsTotalCommission, pointsPaidAmount, pointsIncentives,

      total_sales,total_commission,total_paid,total_due,other_charges,old_pending_balance,sub_total_due,total_incentives,

      } = invoiceData;
    const isValidate = true
    if(!isValidate){
      alert('Incomplete invoice data');
    }else{
      
      let previewURL = `/invoice/preview?`
      previewURL    +=  `autoDownload=Y`
      previewURL    +=  `&store_name=${store}`
      previewURL    +=  `&user_name=${users_name}`
      previewURL    +=  `&pos_number=${pos_number}`
      previewURL    +=  `&mobile=${users_mobile}`
      previewURL    +=  `&totalSales=${total_sales}`
      previewURL    +=  `&totalCommission=${total_commission || 0}`
      previewURL    +=  `&paidAmount=${total_paid || 0}`
      previewURL    +=  `&totalDue=${total_due || 0}`
      previewURL    +=  `&other_charges=${other_charges || 0}`
      previewURL    +=  `&old_balance=${old_pending_balance || 0}`
      previewURL    +=  `&subTotalDue=${sub_total_due || 0}`
      previewURL    +=  `&incentives=${total_incentives || 0}`
      previewURL    +=  `&invoice_date=${invoice_date}`
      previewURL    +=  `&invoice_no=${invoice_no}`
      previewURL    +=  `&payment_mode=${payment_mode}`
      previewURL    +=  `&start_date=${start_date}`
      previewURL    +=  `&start_time=${start_time}`
      previewURL    +=  `&end_date=${end_date}`
      previewURL    +=  `&end_time=${end_time}`
      previewURL    +=  `&sales_person_name=${sales_person_name}`
      previewURL    +=  `&area=${area}`

      previewURL    +=  `&lottoTotalSales=${lottoTotalSales || 0}`
      previewURL    +=  `&lottoTotalCommission=${lottoTotalCommission || 0}`
      previewURL    +=  `&lottoPaidAmount=${lottoPaidAmount || 0}`
      previewURL    +=  `&lottoIncentives=${lottoIncentives || 0}`

      previewURL    +=  `&raffleTotalSales=${raffleTotalSales || 0}`
      previewURL    +=  `&raffleTotalCommission=${raffleTotalCommission || 0}`
      previewURL    +=  `&rafflePaidAmount=${rafflePaidAmount || 0}`
      previewURL    +=  `&raffleIncentives=${raffleIncentives || 0}`

      previewURL    +=  `&pointsTotalSales=${pointsTotalSales || 0}`
      previewURL    +=  `&pointsTotalCommission=${pointsTotalCommission || 0}`
      previewURL    +=  `&pointsPaidAmount=${pointsPaidAmount || 0}`
      previewURL    +=  `&pointsIncentives=${pointsIncentives || 0}`

      
      window.open(previewURL, '_blank');
    }
  } catch (error) { 
    console.log('error', error)
  }
}

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <TobNavBar title={"Invoice Request List"}></TobNavBar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
           {(ALLLISTDATA?.length > 0 && pendingInvoiceCount > 0) && (
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <h4>Successfully processed {pendingInvoiceCount} out of the {ALLLISTDATA.length} invoices.</h4>  
                  <blink >Preparing {pendingInvoice[pendingInvoiceCount - 1]?.store} invoice... </blink>
                </div>                  
              </div>
            </Box>                      
           )} 
            <VendorList 
              >
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Sl No.</th>
                              <th>Customer's Details</th>
                              <th>Period</th>
                              <th>Amount</th>
                              <th>Due Date</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td className="admin_user_list_date"> 
                                      <span>Store : {items?.store}</span><br/>
                                      <span>Name : {items?.users_name}</span><br/> 
                                      <span>Mobile : {items?.users_mobile}</span>
                                    </td>
                                    {/* <td className="admin_user_list_date">
                                      From : {moment(`${items?.start_date}`).subtract(4, 'hours').format("DD-MM-YYYY, hh:mm A")} 
                                      <br/>
                                      To : {moment(`${items?.end_date}`).subtract(4, 'hours').format("DD-MM-YYYY, hh:mm A")} 
                                    </td> */}
                                    <td className="admin_user_list_date">
                                      From : 
                                      {`
                                        ${moment(items?.start_date).subtract(4, 'hours').format("DD-MM-YYYY")} 
                                        ${moment(`2025-03-03 ${items?.start_time}`).format("hh:mm A")}
                                      `} 
                                      <br/>
                                      To : 
                                      {`
                                        ${moment(`${items?.end_date}`).subtract(4, 'hours').format("DD-MM-YYYY")} 
                                        ${moment(`2025-03-03 ${items?.end_time}`).format("hh:mm A")}
                                      `} 
                                    </td>
                                    <td className="admin_user_list_date">{items?.total_due?items.total_due.toFixed(2):'-'}</td>
                                    {items?.due_date?(
                                      <td className="admin_user_list_date">
                                        <h6 dangerouslySetInnerHTML={{ __html: getRelativeTime(items?.due_date) }}></h6>
                                        {moment(`${items?.due_date}`).format("DD-MM-YYYY")}
                                      </td>

                                    ):(
                                      <td className="admin_user_list_date">-</td>
                                    )}
                                      
                                    <td className="admin_user_list_date">{items?.status}</td>
                                    <td>
                                      <Dropdown
                                        overlay={
                                          <>
                                            <Menu>
                                              {items?.status === 'Fail'?(
                                                <Menu.Item>{items?.remarks}</Menu.Item>  
                                              ):(<>
                                                <Menu.Item
                                                  key={`02`}
                                                  onClick={() => {
                                                    handlePDFDownload(items);
                                                  }}
                                                >
                                                <FontAwesomeIcon icon={faDownload} /> <span className="show_span">PDF</span>
                                                </Menu.Item>

                                                <Menu.Item
                                                  key={`01`}
                                                  onClick={() => {
                                                    handleRemove(items._id);
                                                  }}
                                                >
                                                   <FontAwesomeIcon icon={faTrash} /> <span className="show_span">Remove</span>
                                                </Menu.Item>
                                              </>)}
                                            </Menu>
                                          </>
                                        }
                                        placement="bottomLeft"
                                        arrow
                                      >
                                       <FontAwesomeIcon icon={faEllipsis} />
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : isLoading ? <SkeltonList row={10} col={7} /> : <tr> 
                                <td colspan="7" className="img-center" >No data found !</td> 
                            </tr>}
                          </tbody>
                        </table>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </VendorList>
          </Box>
        </div>
      </div>
    </>
  );
}
