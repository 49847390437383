import React, {useState, useEffect} from "react";
import "./main_dashboard.css";
import Top_navbar from "./Top_navbar";
import Recent_order from "./Recent_order";
import { Select, Skeleton } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import {getDashboardData} from '../../controllers/users/usersControllet';
import Box from "@mui/material/Box";

// import * as XLSX from 'xlsx';
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
 const navigate = useNavigate();
   
  

  
  return (
    <>
      <Top_navbar></Top_navbar>
      <div className="container-fluid">
        <div className="row">
          
          <div className="col-md-3 px-0" onClick={()=>navigate('/customers-due/list')}>
            <div className="transaction_form">
              <div className="transaction_Card margin-right">
                <span style={{marginLeft:'10px',marginRight:'10px'}}>  <FontAwesomeIcon icon={faCreditCard} className="sidebar_collapse_iohomeoutline" /></span>
                <div className="transction_main_Card ">
                  <h1 className="dashboard_top_heading_user"> Customer's Due</h1>
                </div>
                <p className="presentation my-2">
                  Navigate to customer's due list
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3 px-0" onClick={()=>navigate('/invoice/list')}>
            <div className="transaction_form">
              <div className="transaction_Card margin-right">
                <span style={{marginLeft:'10px',marginRight:'10px'}}>  <FontAwesomeIcon icon={faCreditCard} className="sidebar_collapse_iohomeoutline" /></span>
                <div className="transction_main_Card ">
                  <h1 className="dashboard_top_heading_user"> Individual Invoice</h1>
                </div>
                <p className="presentation my-2">
                  Navigate to individual invoice list
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-3 px-0"onClick={()=>navigate('/invoice/request')}>
            <div className="transaction_form">
              <div className="transaction_Card margin-right">
                <span style={{marginLeft:'10px',marginRight:'10px'}}>  <FontAwesomeIcon icon={faCreditCard} className="sidebar_collapse_iohomeoutline" /></span>
                <div className="transction_main_Card ">
                  <h1 className="dashboard_top_heading_user"> Bulk Invoice request</h1>
                </div>
                <p className="presentation my-2">
                  Navigate to bulk invoice request list
                </p>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </>
  );
};

export default Dashboard;
