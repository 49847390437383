import React, {useState, useEffect} from "react";
import Preview from '../preview';
import { useLocation } from "react-router-dom";
const DownloadInvoice = () => {
const location = useLocation();
const [invoice_data, setInvoiceData] = useState(location.state || []);
const [nextInvoice, setNextInvoice] = useState(false);
const toggleNextInvoice = () => setNextInvoice(!nextInvoice);
const [currentIndex, setCurrentIndex] = useState(0);

 useEffect(() => {
    if (invoice_data.length > 0) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex < invoice_data.length - 1 ? prevIndex + 1 : prevIndex
        );
        toggleNextInvoice();
      }, 6000);

      return () => clearInterval(interval);
    }
  }, [invoice_data]);
return(<>
    {(invoice_data && invoice_data?.length > 0)?(
        <Preview nextInvoice={nextInvoice} invoiceData={{...invoice_data[currentIndex], autoDownload : 'Y'}} />
    ):(
        <h1>No Invoice Available for download</h1>
    )} 
</>);
}
export default DownloadInvoice;