import React, { useState, useRef, useEffect } from 'react';
// import '../Preview.css'; // Import the CSS file
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import html2pdf from "html2pdf.js";
const Invoice = ({nextInvoice = false, invoiceData={}}) => {
    const printRef = useRef();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const autoClose = queryParams.get('autoClose') || 'N';
    const [PRAMS, setParams] = useState({
        autoDownload: queryParams.get('autoDownload') || 'Y',
        storeName: queryParams.get('store_name') || invoiceData.store || 'N/A',
        userName: queryParams.get('user_name') || invoiceData.users_name || 'N/A',
        area: queryParams.get('area') || invoiceData.area || 'N/A',
        sales_person_name: queryParams.get('sales_person_name') || invoiceData.sales_person_name || 'N/A',
        mobile: queryParams.get('mobile') || invoiceData.users_mobile || 'N/A',
        invoice_date: queryParams.get('invoice_date') || invoiceData.invoice_date || new Date(),
        invoice_no: queryParams.get('invoice_no') || invoiceData.invoice_no || 'N/A',
        pos_number: queryParams.get('pos_number') || invoiceData.pos_number || 'N/A',
        payment_mode: queryParams.get('payment_mode') || invoiceData.payment_mode || 'N/A',
        totalSales: parseFloat(queryParams.get('totalSales') || invoiceData.total_sales || 0),
        totalCommission: parseFloat(queryParams.get('totalCommission') || invoiceData.total_commission || 0),
        paidAmount: parseFloat(queryParams.get('paidAmount') || invoiceData.total_paid || 0),
        totalDue: parseFloat(queryParams.get('totalDue') || invoiceData.total_due || 0),
        subTotalDue: parseFloat(queryParams.get('subTotalDue') || invoiceData.sub_total_due || 0),
        old_balance: parseFloat(queryParams.get('old_balance') || invoiceData.old_pending_balance || 0),
        other_charges: parseFloat(queryParams.get('other_charges') || invoiceData.other_charges || 0),
        incentives: parseFloat(queryParams.get('incentives') || invoiceData.incentives || 0),
        start_date: queryParams.get('start_date') || invoiceData.start_date || '',
        start_time: queryParams.get('start_time') || invoiceData.start_time || '',
        end_date: queryParams.get('end_date') || invoiceData.end_date || '',
        end_time: queryParams.get('end_time') || invoiceData.end_time || '',

        lottoTotalSales : parseFloat(queryParams.get('lottoTotalSales') || invoiceData?.lottoTotalSales || 0),
        lottoTotalCommission : parseFloat(queryParams.get('lottoTotalCommission') || invoiceData?.lottoTotalCommission || 0),
        lottoPaidAmount : parseFloat(queryParams.get('lottoPaidAmount') || invoiceData?.lottoPaidAmount || 0),
        lottoIncentives : parseFloat(queryParams.get('lottoIncentives') || invoiceData?.lottoIncentives || 0),

        raffleTotalSales : parseFloat(queryParams.get('raffleTotalSales') || invoiceData?.raffleTotalSales || 0),
        raffleTotalCommission : parseFloat(queryParams.get('raffleTotalCommission') || invoiceData?.raffleTotalCommission || 0),
        rafflePaidAmount : parseFloat(queryParams.get('rafflePaidAmount') || invoiceData?.rafflePaidAmount || 0),
        raffleIncentives : parseFloat(queryParams.get('raffleIncentives') || invoiceData?.raffleIncentives || 0),

        pointsTotalSales : parseFloat(queryParams.get('pointsTotalSales') || invoiceData?.pointsTotalSales || 0),
        pointsTotalCommission : parseFloat(queryParams.get('pointsTotalCommission') || invoiceData?.pointsTotalCommission || 0),
        pointsPaidAmount : parseFloat(queryParams.get('pointsPaidAmount') || invoiceData?.pointsPaidAmount || 0),
        pointsIncentives : parseFloat(queryParams.get('pointsIncentives') || invoiceData?.pointsIncentives || 0),
       
    });

    const generatePdf = async () => {
        try {
            if(invoiceData?.autoDownload === 'Y' || queryParams.get('autoDownload') === 'Y'){
                const element = printRef.current;
                const options = {
                    margin      : .25,
                    filename    : `${invoiceData?.pos_number?invoiceData?.pos_number:PRAMS.pos_number} Invoice - ${moment(new Date()).format('DD-MM-YYYY')}.pdf`,
                    image       : { type: "jpeg", quality: 1 },
                    html2canvas : { dpi: 1200, letterRendering: true, scale: 3 },
                    jsPDF       : { unit: "in", format: [8.5, 11], orientation: "portrait" }
                };
                await html2pdf().from(element).set(options).save();
            }
        } finally {
            // if(autoClose === 'Y'){
            //     window.close();
            // }
        }
      };

      useEffect(()=>{
        const isDataReady = Object.values(invoiceData).length > 0;
        if (isDataReady) {
            setParams({
                autoDownload: queryParams.get('autoDownload') || invoiceData?.autoDownload || 'Y',
                storeName: invoiceData.store || 'N/A',
                userName:invoiceData.users_name || 'N/A',
                mobile:invoiceData.users_mobile || 'N/A',
                area:invoiceData.area || 'N/A',
                sales_person_name:invoiceData.sales_person_name || 'N/A',
                invoice_date: invoiceData.invoice_date || new Date(),
                invoice_no: invoiceData.invoice_no || 'N/A',
                pos_number: invoiceData.pos_number || 'N/A',
                payment_mode: invoiceData.payment_mode || 'N/A',
                totalSales: parseFloat(invoiceData.total_sales || 0),
                totalCommission: parseFloat(invoiceData.total_commission || 0),
                paidAmount: parseFloat(invoiceData.total_paid || 0),
                totalDue: parseFloat(invoiceData.total_due || 0),
                subTotalDue: parseFloat(invoiceData.sub_total_due || 0),
                old_balance: parseFloat(invoiceData.old_pending_balance || 0),
                other_charges: parseFloat(invoiceData.other_charges || 0),
                incentives: parseFloat(invoiceData.incentives || 0),
                start_date: invoiceData.start_date || '',
                start_time: invoiceData.start_time || '',
                end_date: invoiceData.end_date || '',
                end_time: invoiceData.end_time || '',

                lottoTotalSales : parseFloat(invoiceData?.lottoTotalSales || 0),
                lottoTotalCommission : parseFloat(invoiceData?.lottoTotalCommission || 0),
                lottoPaidAmount : parseFloat(invoiceData?.lottoPaidAmount || 0),
                lottoIncentives : parseFloat(invoiceData?.lottoIncentives || 0),

                raffleTotalSales : parseFloat(invoiceData?.raffleTotalSales || 0),
                raffleTotalCommission : parseFloat(invoiceData?.raffleTotalCommission || 0),
                rafflePaidAmount : parseFloat(invoiceData?.rafflePaidAmount || 0),
                raffleIncentives : parseFloat(invoiceData?.raffleIncentives || 0),

                pointsTotalSales : parseFloat(invoiceData?.pointsTotalSales || 0),
                pointsTotalCommission : parseFloat(invoiceData?.pointsTotalCommission || 0),
                pointsPaidAmount : parseFloat(invoiceData?.pointsPaidAmount || 0),
                pointsIncentives : parseFloat(invoiceData?.pointsIncentives || 0),
            });
            // generatePdf();
        }else{
        }
        generatePdf();
      },[]);
    //   console.log('invoiceData : ', invoiceData);
  return (
  <>
    <div className="invoice-container" id="invoice" ref={printRef} style={{
        maxWidth: '800px',
        margin: 'auto',
        backgroundColor: '#fff',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        fontSize: '13px',
      }}>
        <div className="logo-image" style={{display: 'flex',justifyContent: 'center'}}>
            <img
            src='/static/media/SideBarIcon.2269b347427947c916aa.png'
            alt="Logo"
            style={{width: "12%"}}
            />
        </div>
        <h4 className="invoice-title" style={{textAlign: 'center',marginBottom: '10px'}}>STATEMENT OF ACCOUNT</h4>
        <table className="invoice-table" style={{width: '100%',borderCollapse: 'collapse',marginBottom: '10px'}}>
            <tbody>
                <tr>
                    <th colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'left',}}>Invoice To</th>
                    <th colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'left',}}>Invoice No.</th>
                    <th colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'left',}}>Dated</th>
                </tr>
                <tr>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}><b>{PRAMS?.storeName || ''}</b></td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>{PRAMS.invoice_no || 'N/A'}</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>{moment(PRAMS?.invoice_date).format("DD-MM-YYYY")}</td>
                </tr>
                <tr>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'left'}}>Sales person's name</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'left'}}>Machine Ref.</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'left'}}>Mode/Terms of Payment</td>
                </tr>
                <tr>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>{PRAMS?.sales_person_name || ''}</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}><b>{PRAMS?.pos_number || 'N/A'}</b></td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>{PRAMS?.payment_mode || 'N/A'}</td>
                </tr>
                
                <tr>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'left'}}>Customer's Details</td>
                    <td colSpan="4" className="text-left" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'left'}}>Duration</td>
                </tr>
                <tr>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}><b>{`${PRAMS?.userName} | ${PRAMS?.mobile} | ${PRAMS?.area}` || ''}</b></td>
                    <td colSpan="4" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>
                        {/* {PRAMS?.start_date} */}
                        {/* {moment(PRAMS?.start_date).subtract(0, 'hours').format("DD-MM-YYYY, hh:mm A")} to {moment(PRAMS?.end_date).subtract(0, 'hours').format("DD-MM-YYYY, hh:mm A")} */}
                        {`
                            ${moment(PRAMS?.start_date).subtract(4, 'hours').format("DD-MM-YYYY")} 
                            ${moment(`2025-03-03 ${PRAMS?.start_time}`).format('hh:mm A')}
                            to ${moment(PRAMS?.end_date).subtract(4, 'hours').format("DD-MM-YYYY")}
                            ${moment(`2025-03-03 ${PRAMS?.end_time}`).format('hh:mm A')}
                        `}
                    </td>
                </tr>

            </tbody>
        </table>

        <table className="invoice-table" style={{width: '100%',borderCollapse: 'collapse',marginBottom: '10px'}}>
            <tbody>
                <tr>
                    <th colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'left',}}>Description</th>
                    <th colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'center',}}>Daily Draw</th>
                    <th colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'center',}}>Raffle</th>
                    <th colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'center',}}>U-Points</th>
                    <th colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'center',}}>Total</th>
                </tr>
                <tr>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}> Total Sale </td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}> {PRAMS?.lottoTotalSales?.toFixed(2) || 0} </td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}> {PRAMS?.raffleTotalSales?.toFixed(2) || 0} </td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}> {PRAMS?.pointsTotalSales?.toFixed(2) || 0} </td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}> {PRAMS?.totalSales?.toFixed(2) || 0} </td>
                </tr>
                <tr>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>Less: Commission</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}>{PRAMS?.lottoTotalCommission?.toFixed(2) || 0}</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}>{PRAMS?.raffleTotalCommission?.toFixed(2) || 0}</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}>{PRAMS?.pointsTotalCommission?.toFixed(2) || 0}</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}>{PRAMS?.totalCommission?.toFixed(2) || 0}</td>
                </tr>
                <tr>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>Less: Amount paid for raffle redeem directly by agent</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}>{PRAMS?.lottoPaidAmount?.toFixed(2) || 0}</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}>{PRAMS?.rafflePaidAmount?.toFixed(2) || 0}</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}>{PRAMS?.pointsPaidAmount?.toFixed(2) || 0}</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}>{PRAMS?.paidAmount?.toFixed(2) || 0}</td>
                </tr>
                <tr>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>Less: Other incentives</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}>{PRAMS?.lottoIncentives?.toFixed(2) || 0}</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}>{PRAMS?.raffleIncentives?.toFixed(2) || 0}</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}>{PRAMS?.pointsIncentives?.toFixed(2) || 0}</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}>{PRAMS?.incentives?.toFixed(2) || 0}</td>
                </tr>
                <tr>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}><strong>Net amount</strong></td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}><b>
                        {PRAMS?.lottoTotalSales - (PRAMS?.lottoTotalCommission + PRAMS?.lottoPaidAmount + PRAMS?.lottoIncentives)?.toFixed(2) || 0}
                        </b></td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}><b>
                        {PRAMS?.raffleTotalSales - (PRAMS?.raffleTotalCommission + PRAMS?.rafflePaidAmount + PRAMS?.raffleIncentives)?.toFixed(2) || 0}
                        </b></td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}><b>
                        {PRAMS?.pointsTotalSales - (PRAMS?.pointsTotalCommission + PRAMS?.pointsPaidAmount + PRAMS?.pointsIncentives)?.toFixed(2) || 0}
                        </b></td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}><b>
                        {(PRAMS?.totalSales - PRAMS?.totalCommission - PRAMS?.paidAmount - PRAMS?.incentives)?.toFixed(2) || 0}
                        </b></td>
                </tr>
                <tr>
                    <td colSpan="8" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>Old balance pending</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}>{PRAMS?.old_balance?.toFixed(2) || 0}</td>
                </tr>
                <tr>
                    <td colSpan="8" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>Other charges (if any)</td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}>{PRAMS?.other_charges?.toFixed(2) || 0}</td>
                </tr>
                <tr>
                    <td colSpan="8" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}><strong>Total due as per statement</strong></td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'right'}}>
                        <b> {((PRAMS?.totalSales + PRAMS?.old_balance + PRAMS?.other_charges) - (PRAMS?.totalCommission + PRAMS?.paidAmount + PRAMS?.incentives))?.toFixed(2) || 0}</b>
                    </td>
                </tr>
            </tbody>
        </table>

        <table className="invoice-table" style={{width: '100%',borderCollapse: 'collapse',marginBottom: '5px'}}>
            <tbody>
                <tr>
                    <th colSpan="8" className="text-center" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'left',}}>To be filled by Uwinn representative</th>
                </tr>
                <tr>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>
                    <strong> Date of collection </strong>
                    </td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>
                    <strong>Cash received</strong>
                    </td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>
                    <strong>No. of tickets redeemed</strong> 
                    </td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>
                    <strong>Total value of redeem tickets</strong>
                    </td>
                </tr>
                <tr>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>
                    </td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>
                    </td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>
                    </td>
                    <td colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>
                    </td>
                </tr>
                <tr>
                    <td colSpan="4" className="text-center" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>
                    <strong>Balance due</strong> 
                    </td>
                    <td colSpan="4" className="text-center" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>
                    <strong>Due date</strong> 
                    </td>
                </tr>
                <tr>
                    <td colSpan="4" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>
                    </td>
                    <td colSpan="4" className="text-left" style={{border: '1px solid #ddd',padding: '8px',textAlign: 'left'}}>
                    </td>
                    
                </tr>
            </tbody>
        </table>

        <table className="invoice-table" style={{width: '100%',borderCollapse: 'collapse',marginBottom: '5px'}}>
            <thead>
                <tr>
                <th colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'left'}}>Uwinn accounts dept</th>
                <th colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'left'}}>Uwinn sales executive</th>
                <th colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'left'}}>Agent name: Tel</th>
                <th colSpan="2" className="text-left" style={{border: '1px solid #ddd',padding: '8px',backgroundColor: '#f2f2f2',textAlign: 'left'}}>Agent signature</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td colSpan="2" style={{ border: '1px solid #ddd',padding: '8px', textAlign: 'left', height: '50px' }} className="text-left"></td>
                <td colSpan="2" style={{ border: '1px solid #ddd',padding: '8px', textAlign: 'left', height: '50px' }} className="text-left"></td>
                <td colSpan="2" style={{ border: '1px solid #ddd',padding: '8px', textAlign: 'left', height: '50px' }} className="text-left"></td>
                <td colSpan="2" style={{ border: '1px solid #ddd',padding: '8px', textAlign: 'left', height: '50px' }} className="text-left"></td>
                </tr>
            </tbody>
        </table>

        {/* Additional tables with similar structure */}
        <div className="footer" style={{ marginTop: '10px',textAlign: 'center' }}>
            <strong>U WINN L.L.C-FZ</strong>
            <p> The Meydan Hotel, Grandstand, 6th floor, Meydan Road, Nad Al Sheba, Dubai, UAE </p>
        </div>
    </div>
  </>);
};

export default Invoice;
