import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Typography} from "antd";
import { notification } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faTimesCircle } from '@fortawesome/free-regular-svg-icons';  
import { InfoCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

import { addeditdata, updateDueAmount } from "../../../controllers/V1/customerDue/dueController";

import ExcelJS from 'exceljs';
import { Link } from "react-router-dom";

const VendorListDeletePopUp = ({ popUpOpen, togglePopUp, edit_data, setEditData }) => {
  const handleCancel = () => {
    togglePopUp();
    setJsonData({});
    setIsBulkUpload(false);
    setDueCount(0);
    setProgressBar(false);
    setEditData('');
  };
  const [ errors, setError] = useState({});
  const [ isBulkUpload, setIsBulkUpload] = useState(false);
  const toggleBulkUpload = () => {
    setIsBulkUpload(!isBulkUpload);
    setJsonData([]);
  }
  const [jsonData, setJsonData] = useState([]);
  const [progressBar, setProgressBar] = useState(false);
  const [dueCount, setDueCount] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);
  const toggleProgressBar = ()=>setProgressBar(!progressBar);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError({});
      const form = new FormData(e.target);
      if(!form.get('search_by')){
        setError((pre)=>({
          ...pre,
          'search_by' : "Search by is required."
        }));
      }else if(!form.get('search_value')){
        setError((pre)=>({
          ...pre,
          'search_value' : "Search value is required."
        }));
      }else if(!form.get('due_amount')){
        setError((pre)=>({
          ...pre,
          'due_amount' : "Due amount is required."
        }));
      }else{
        const option = {
          search_by     : form.get('search_by'),
          search_value  : parseInt(form.get('search_value')),
          due_amount    : parseFloat(form.get('due_amount'))
        }
        const res = await addeditdata(option);
        if(res.status === true){
          notification.open({
            message: "Success.",
            description: `Data successfully added.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          handleCancel();
        } else {
          notification.open({
            message: "Oops!.",
            description: `No data found.`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      }
    } catch (error) {
      
    }
  }



  const handleChangeFile = (e) => {
    try {
      console.log('file : ', e.target.files[0]);
      const file = e.target.files[0];
      const workbook = new ExcelJS.Workbook();
      const reader = new FileReader();

      reader.onload = async (event) => {
        const buffer = event.target.result;
        await workbook.xlsx.load(buffer);
  
        const worksheet = workbook.worksheets[0];
        const rows = [];
  
        worksheet.eachRow((row, rowNumber) => {
          const rowValues = row.values.slice(1); // Remove the first empty value
          rows.push(rowValues);
        });
  
        // Convert rows to JSON (assuming first row is the header)
        const headers = rows[0];
        const data = rows.slice(1).map(row => {
          const rowData = {};
          row.forEach((cell, index) => {
            rowData[headers[index]] = cell;
          });
          return rowData;
        });
        setJsonData(data);
        // console.log('data',data)
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.log('error',error);
    }
  }

  const generateDue = () =>{
    try {
      if(jsonData.length > 0){
        toggleProgressBar();
        setDueCount(jsonData.length);
        // notification.open({
        //   message: "Success.",
        //   description: `Invoice generated Successfully.`,
        //   placement: "topRight",
        //   icon: <CheckCircleOutlined style={{ color: "green" }} />,
        //   duration: 2,
        // });
      } else{
        notification.open({
          message: "Oops!.",
          description: `No data found.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      setDueCount(false);
      notification.open({
        message: "Oops!.",
        description: `No data found.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
  }

  const handleBulkSubmit = async (option = {}) => {
    try {
        const params = {
          ...(option?.mobile?{
            search_by : 'users_mobile',
            search_value : parseInt(option.mobile)
          }:{
            search_by : 'pos_number',
            search_value : option.pos_number
          }),
          due_amount : option?.amount || 0
        }
        const res = await addeditdata(params);
        setDueCount(dueCount - 1);
        if(res.status === true){
          setJsonData((prev) =>
            prev.map((item) =>
              item.mobile === option.mobile ? { 
                ...item, 
                status: 'Completed', 
                remarks : ''
              } : item
            )
          );
        } else{
          setJsonData((prev) =>
            prev.map((item) =>
              item.mobile === option.mobile ? { 
                ...item, 
                status: 'Fail', 
                remarks : res?.message
              } : item
            )
          );
        }
    } catch (error) {
      console.log('error',error);
    }
  }

  useEffect(()=>{
    if(dueCount > 0){
      handleBulkSubmit(jsonData[dueCount - 1])
    }
  },[dueCount]);

  const handleDueChange = (e) => {
    setDueAmount(e.target.value);
  }

  const handleUpdate = async () => {
    try {
      if(!dueAmount){
        setError((pre)=>({
          ...pre,
          due_amount : "Due amount is required."
        }))
      } else {
        const option = {
          edit_id : edit_data?._id,
          due_amount : dueAmount || 0
        }
        const res = await updateDueAmount(option);
        if(res.status === true){
          notification.open({
            message: "Success.",
            description: `Data successfully updated.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          handleCancel();
        }else {
          notification.open({
            message: "Oops!.",
            description: res?.message,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      }
    } catch (error) {
      
    }
  }
  return (
    <Dialog
      open={popUpOpen}
      onClose={handleCancel}
      fullWidth={true}
      maxWidth={"md"}
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              Add customer's due
            </Typography>
            <Link to={false} style={{ position: "absolute", right: 8, top: 8, color: "#dd5151" }} onClick={handleCancel}><FontAwesomeIcon icon={faTimesCircle}/></Link>
          </div>

          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="inventory_open_popup_action_btn">
                  {!edit_data && (<>
                    <button type="button" className={`btn btn-${!isBulkUpload?'dark':'light'}`} onClick={toggleBulkUpload} > Single </button>
                    <button type="button" className={`btn btn-${isBulkUpload?'dark':'light'}`} onClick={toggleBulkUpload} > Multiple </button>
                  </>)}
                </div>
              </div>
            </div>
          </Typography>
          {!isBulkUpload ? (
            <Typography
              variant="body1"
              component="div"
              className="my_delete_popup_paragraph"
            >
              <div className="container-fluid">
                <div className="categories_open_popup_main_contnet">
                  <form className="categories_open_popup_form" onSubmit={handleSubmit} >
                    <input type="hidden" name="id" id="id"/>
                    <div className="form-group row">
                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label for="title" className="all_lable_for_vendor_dashbaord" >
                        <span style={{color:"red"}}>*</span>Search by :
                        </label>
                        <select class="form-control categories_select_tg_enter_product" name="search_by" id="search_by" disabled={edit_data?true:false} >
                          <option value="" selected disabled >Choose an option</option>
                          <option value="users_mobile">Mobile</option>
                          <option value="pos_number" selected={edit_data.pos_number?true:false} >POS No.</option>
                        </select>
                        {errors?.search_by?(<span className="text-danger">{errors.search_by}</span>):""}
                      </div>

                      <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <label for="short_description" className="all_lable_for_vendor_dashbaord" >
                        <span style={{color:"red"}}>*</span>Search Value
                        </label>
                        {edit_data?.pos_number ? (
                          <input type="text" name="search_value" id="search_value" value={edit_data?.pos_number} disabled class="form-control categories_select_tg_enter_product"
                          placeholder="Enter search value"/>
                        ):(
                          <input type="text" name="search_value" id="search_value" class="form-control categories_select_tg_enter_product"
                          placeholder="Enter search value"/>
                        )}
                        
                        {errors?.search_value?(<span className="text-danger">{errors.search_value}</span>):""}
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <label for="short_description" className="all_lable_for_vendor_dashbaord" >
                        <span style={{color:"red"}}>*</span>Due Amount
                        </label>
                        <input type="number" name="due_amount" id="due_amount" onChange={handleDueChange} class="form-control categories_select_tg_enter_product" placeholder="Enter due amount"/>
                        {errors?.due_amount?(<span className="text-danger">{errors.due_amount}</span>):""}
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="inventory_open_popup_action_btn">
                          <button type="button" class="btn btn-light categories_cancel_button" onClick={handleCancel} > Cancel </button>
                          {edit_data ? (
                            <button type="button" class="btn btn-danger" onClick={handleUpdate} > Update </button>
                          ):(
                            <button type="Submit" class="btn btn-danger" > Create </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Typography>
          ):(
            <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <div className="form-group row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label for="due_list" className="all_lable_for_vendor_dashbaord" >
                    <span style={{color:"red"}}>*</span>Choose an file :
                    </label>
                    <input type="file" name="due_list" id="due_list" onChange={handleChangeFile} class="form-control categories_select_tg_enter_product" />
                    {errors?.search_by?(<span className="text-danger">{errors.search_by}</span>):""}
                  </div>

                  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <Link to="/templates/sample template.xlsx" target="__blank">
                      <label for="title" className="all_lable_for_vendor_dashbaord" > Download template </label>
                    </Link>
                  </div>

                  {(progressBar && jsonData?.length > 0) && (
                    <div className="inventory_popup_title">
                      <h5>
                        Pending {dueCount} out of {jsonData?.length}
                      </h5>
                    </div>                          
                    )}

                  <div 
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12" 
                    style={{
                      maxHeight: "220px",
                      overflowY: "auto",
                      border: "1px solid #ddd",
                    }}>
                    <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0" >
                      <thead>
                        <tr>
                          <th>Sl. No.</th>
                          <th>POS Number</th>
                          <th>Mobile Number</th>
                          <th>Due Amount</th>
                          <th>Status</th>
                          <th>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jsonData?.length > 0 ? (
                          jsonData.map((items, index) => (
                            <>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{items?.pos_number}</td>
                                <td>{items?.mobile}</td>
                                <td>{items?.amount}</td>
                                <td>{items?.status?items.status:"Pending"}</td>
                                <td>{items?.remarks}</td>
                              </tr>
                            </>))
                          ):(
                            <tr> 
                              <td colspan="5" className="img-center" >No data found !</td> 
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="inventory_open_popup_action_btn">
                      <button type="button" className="btn btn-light categories_cancel_button" onClick={handleCancel} > Cancel </button>
                      <button type="button" className="btn btn-danger" onClick={generateDue}> Generate </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Typography>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default VendorListDeletePopUp;
