import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Typography} from "antd";
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';  
import { list } from "../../../controllers/V1/customerDue/dueController";

import Loading from "../../../components/Loading/LoadingEffect";
import { Link } from "react-router-dom";
const VendorListDeletePopUp = ({ popUpOpen, togglePopUp }) => {
  const handleCancel = () =>  togglePopUp();

  const [isLoading, setIsLoading] = useState(false);
  
  const handleExport = async (e) => {
    e.preventDefault(0);
    try {
      setIsLoading(true);
      const formData = new FormData(e.target);

      const option = {
        condition : { 
          ...(formData.get('name')?{name : { $regex: formData.get('name'), $options: "i" }}:null),
          ...(formData.get('users_mobile')?{users_mobile : parseInt(formData.get('users_mobile'))}:null),
          ...(formData.get('store')?{store : { $regex: formData.get('store'), $options: "i" }}:null),
          ...(formData.get('bind_person_name')?{bind_person_name : { $regex: formData.get('bind_person_name'), $options: "i" }}:null),
          ...(formData.get('bind_person_id')?{bind_person_id : parseInt(formData.get('bind_person_id'))}:null)
         },
         select : {
          ...(
            formData.get('select_users_id') ||
            formData.get('select_users_type') ||
            formData.get('select_name') ||
            formData.get('select_store') ||
            formData.get('select_users_mobile') ||
            formData.get('select_bind_person_id') ||
            formData.get('select_bind_person_name') ||
            formData.get('select_pos_number') ||
            formData.get('select_due_amount') ||
            formData.get('select_status') ||
            formData.get('select_updatedAt') ?({
              ...(formData.get('select_users_id')?{users_id : true}:null),
              ...(formData.get('select_users_type')?{users_type : true}:null),
              ...(formData.get('select_name')?{name : true}:null),
              ...(formData.get('select_store')?{store : true}:null),
              ...(formData.get('select_users_mobile')?{users_mobile : true}:null),
              ...(formData.get('select_bind_person_id')?{bind_person_id : true}:null),
              ...(formData.get('select_bind_person_name')?{bind_person_name : true}:null),
              ...(formData.get('select_pos_number')?{pos_number : true}:null),
              ...(formData.get('select_due_amount')?{due_amount : true}:null),
              ...(formData.get('select_status')?{status : true}:null),
              ...(formData.get('select_updatedAt')?{updatedAt : true}:null)
            }):({
              _id : false,
              __v : false,
              updated_by : false,
              updated_ip : false,
              created_ip : false,
              created_by : false,
              country_code : false,
              users_oid : false
            })
          )
         }
      }
      const res = await list(option);
      if(res.status === true){
        await exportToExcel(res.result);
        handleCancel();
      }else{
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  const exportToExcel = async (data) => {
    try {
      const workbook = XLSX.utils.book_new();
      const worksheet1 = XLSX.utils.json_to_sheet(data);

      XLSX.utils.book_append_sheet(workbook, worksheet1, "Due");
      XLSX.writeFile(workbook, `${new Date()}.xlsx`);
    } catch (error) {
      alert(JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }

  };
  
  return (
    <Dialog
      open={popUpOpen}
      onClose={handleCancel}
      fullWidth={true}
      maxWidth={"md"}
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              Export customer's due
            </Typography>
            <Link to={false} style={{ position: "absolute", right: 8, top: 8, color: "#dd5151" }} onClick={handleCancel}><FontAwesomeIcon icon={faTimesCircle}/></Link>
          </div>

          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
          </Typography>
         
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <form onSubmit={handleExport}>
                  <div className="form-group row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label for="bind_person_name" className="all_lable_for_vendor_dashbaord" > Bind with user's name : </label>
                      <input type="text" name="bind_person_name" id="bind_person_name" placeholder="Enter bind whit user's name" class="form-control categories_select_tg_enter_product" />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label for="bind_person_id" className="all_lable_for_vendor_dashbaord" > Bind with user's id : </label>
                      <input type="number" name="bind_person_id" id="bind_person_id" placeholder="Enter bind whit user's id" class="form-control categories_select_tg_enter_product" />
                    </div>

                    <div className="col-lg-6 col-md-5 col-sm-12 col-xs-12">
                      <label for="name" className="all_lable_for_vendor_dashbaord" > Name : </label>
                      <input type="text" name="name" id="name" placeholder="Enter user's name" class="form-control categories_select_tg_enter_product" />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <label for="users_mobile" className="all_lable_for_vendor_dashbaord" > Mobile number : </label>
                      <input type="number" name="users_mobile" id="users_mobile" placeholder="Enter user's mobile number" class="form-control categories_select_tg_enter_product" />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label for="store" className="all_lable_for_vendor_dashbaord" > Store's Name : </label>
                      <input type="text" name="store" id="store" placeholder="Enter user's store" class="form-control categories_select_tg_enter_product" />
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <label for="select_name" className="all_lable_for_vendor_dashbaord" > Export fields (If any) : </label>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <label for="select_users_id" className="all_lable_for_vendor_dashbaord" > 
                        <input type="checkbox" name="select_users_id" id="select_users_id" className="form-check-input" /> User's Id 
                      </label>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <label for="select_users_type" className="all_lable_for_vendor_dashbaord" > 
                        <input type="checkbox" name="select_users_type" id="select_users_type" className="form-check-input" /> User's type 
                      </label>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <label for="select_name" className="all_lable_for_vendor_dashbaord" > 
                        <input type="checkbox" name="select_name" id="select_name" className="form-check-input" /> Name 
                      </label>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <label for="select_store" className="all_lable_for_vendor_dashbaord" > 
                        <input type="checkbox" name="select_store" id="select_store" className="form-check-input" /> Store's Name 
                      </label>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <label for="select_users_mobile" className="all_lable_for_vendor_dashbaord" > 
                        <input type="checkbox" name="select_users_mobile" id="select_users_mobile" className="form-check-input" /> Store's mobile 
                      </label>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <label for="select_bind_person_id" className="all_lable_for_vendor_dashbaord" > 
                        <input type="checkbox" name="select_bind_person_id" id="select_bind_person_id" className="form-check-input" /> Bind with user Id 
                      </label>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <label for="select_bind_person_name" className="all_lable_for_vendor_dashbaord" > 
                        <input type="checkbox" name="select_bind_person_name" id="select_bind_person_name" className="form-check-input" /> Bind with user' name 
                      </label>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <label for="select_pos_number" className="all_lable_for_vendor_dashbaord" > 
                        <input type="checkbox" name="select_pos_number" id="select_pos_number" className="form-check-input" /> POS number 
                      </label>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <label for="select_due_amount" className="all_lable_for_vendor_dashbaord" > 
                        <input type="checkbox" name="select_due_amount" id="select_due_amount" className="form-check-input" /> Due amount 
                      </label>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <label for="select_status" className="all_lable_for_vendor_dashbaord" > 
                        <input type="checkbox" name="select_status" id="select_status" className="form-check-input" /> Status 
                      </label>
                    </div>
                    
                    <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                      <label for="select_updatedAt" className="all_lable_for_vendor_dashbaord" > 
                        <input type="checkbox" name="select_updatedAt" id="select_updatedAt" className="form-check-input" /> Last Update 
                      </label>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="inventory_open_popup_action_btn">
                        <button type="button" className="btn btn-light categories_cancel_button" onClick={handleCancel} > Cancel </button>
                        <button type="submit" className="btn btn-danger" > Download Excel </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Typography>
        </div>
      </div>
      <Loading isLoading={isLoading} text={`Preparing download...`} />
    </Dialog>
  );
};

export default VendorListDeletePopUp;
