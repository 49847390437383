import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, notification, Drawer, Button, Space } from "antd";
import Pagination from "@mui/material/Pagination";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faEdit, faTrash, faPlus, faEllipsis, faFileExport, faCloudDownload, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

import TobNavBar from "../../DashBaord/Top_navbar";

import { Link, useNavigate } from "react-router-dom";

import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  LikeOutlined,
  DislikeOutlined,
} from "@ant-design/icons";

import moment from "moment";
import SkeltonList from "../../../components/SkeltonEffect/list";
import {
  list,
} from "../../../controllers/V1/customerDue/dueController";
import { getPage, getRelativeTime } from "../../../controllers/common";

import {API_BASE_URL} from "../../../config/constants";

import AddEditDataPopUp from "./AddEditDataPopUp";
import ExportPopup from './Export';
import ImportPopup from "./Import"
import ImportCollection from "./ImportCollection";

function VendorList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

VendorList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {


  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () => setFilterShow(!isFilterShow);

  const [isExportShow, setExportShow] = useState(false);
  const handleExportDrawer = () => setExportShow(!isExportShow);

  const [isImportShow, setImportShow] = useState(false);
  const handleImportDrawer = () => setImportShow(!isImportShow);

  const [isImportCollectionShow, setImportCollectionShow] = useState(false);
  const handleImportCollection = () => setImportCollectionShow(!isImportCollectionShow);

  
  const [addEditPopup, setAddEditPopup] = useState(false);
  const toggleAddEditPopup = () => setAddEditPopup(!addEditPopup);

  const [edit_data, setEditData] = useState('');

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  /************************************************************************************* */
  const [isLoading, setIsLoading] =useState(true);
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: new Date(),
    filter_by: "",
    search: "",
  }); //End

  const [showRequest, setShowRequest] = useState("");


  /*********************************************************
   *  This function is use to fetch vendor list
   *********************************************************/
  const getList = async () => {
    try {
      setIsLoading(true);
      setListData([]);
      const options = {
        type: "",
        condition: {
          ...(filter?.filter_by === 'name'?{name : { $regex: filter?.search, $options: "i" } }:null),
          ...(filter?.filter_by === 'store'?{store : { $regex: filter?.search, $options: "i" } }:null),
          ...(filter?.filter_by === 'users_mobile'?{users_mobile : filter?.search }:null),
          ...(filter?.filter_by === 'due_amount'?{due_amount : filter?.search }:null),
          ...(filter?.filter_by === 'bind_person_name'?{bind_person_name : { $regex: filter?.search, $options: "i" } }:null),
          ...(filter?.filter_by === 'bind_person_id'?{bind_person_id : filter?.search }:null),
          ...(showRequest ? { status: showRequest } : null),
        },
        select: {},
        sort: { status: 1, _id: -1 },
        skip: SKIP ? SKIP : 0,
        limit: LIMIT ? LIMIT : 10,
      };
      const listData = await list(options);
      if (listData.status === true) {
        if (listData.result.length > 0) {
          setListData(listData?.result);
          setTotalPages(getPage(listData?.count))
        } else {
          setListData([]);
          setTotalPages(getPage(1))
        }
      } else {
        setListData([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }; //End

  /*********************************************************
   *  This function is for handle page change
   *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  /*********************************************************
   *  This function is ued for handle filter input change
   *********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter('');
      inputRef1.current.value = '';
      inputRef2.current.value = '';
      inputRef3.current.value = '';
      inputRef4.current.value = '';
    } catch (error) {
      
    }
  }

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter((pre)=>({
        ...pre,
        'filter_by' : form.get('filter_by') || '',
        'search'    : form.get('search') || '',
        'to'        : form.get('to_date') || '',
        'from'      : form.get('from_date') || '',

      }))
      // console.log('filter',filter);
      // alert('submit');
      handleFiterDrawer();
      setSkip(0);
    } catch (error) {
      
    }
  }

  /*********************************************************
   *  This function will load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior:'smooth',
    });
    document.title = "U-Winn CRM || Customer's due list";
  }, [currentPage, showRequest, filter, addEditPopup, isImportCollectionShow, isImportShow]);

  useEffect(()=>{
    if(edit_data){
      setAddEditPopup(true);
    }
  },[edit_data])
  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <TobNavBar title={"Customer's due list"}></TobNavBar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                      onClick={() => setShowRequest("")}
                    />
                  </Tabs>
                </div>

                <div className="fa_filter_rating_review filter_img_and_add_btn_main">
                  <div className="inventory_filter_div" onClick={handleFiterDrawer}>
                    <FontAwesomeIcon icon={faFilter} />
                    <Link
                      to={false}
                      className="filter_btn_invontry_rating_review"
                    >
                      Filter
                    </Link>
                  </div>

                  <div className="inventory_filter_div" onClick={handleExportDrawer}>
                    <FontAwesomeIcon icon={faCloudDownload} />
                    <Link
                      to={false}
                      className="filter_btn_invontry_rating_review"
                    >
                      Export
                    </Link>
                  </div>

                  <div className="inventory_filter_div" onClick={handleImportDrawer}>
                    <Link to={false} class="filter_btn_invontry_rating_review">
                      <FontAwesomeIcon icon={faCloudArrowUp} />
                      Import
                    </Link>
                  </div>

                  <div className="inventory_filter_div" onClick={handleImportCollection}>
                    <Link to={false} class="filter_btn_invontry_rating_review">
                      <FontAwesomeIcon icon={faCloudArrowUp} />
                      Upload collection
                    </Link>
                  </div>

                  <div onClick={() => toggleAddEditPopup()}>
                    <button type="button" class="btn btn-dark">
                      <span className="request_new_categories">Add New</span>
                    </button>
                  </div>
                </div>
              </div>
            </Box>            
            <VendorList 
              >
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Sl. No.</th>
                              <th>POS Number</th>
                              <th>Customer's Details</th>
                              <th>Bind With</th>
                              <th>Due Amount</th>
                              <th>Updated At</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{items?.pos_number}</td>
                                    <td className="admin_user_list_date"> 
                                      <span>Store : {items?.store}</span><br/>
                                      <span>Name : {items?.name}</span><br/> 
                                      <span>Mobile : {items?.users_mobile}</span>
                                    </td>
                                    <td className="admin_user_list_date">{items?.bind_person_name}</td>
                                    <td className="admin_user_list_date">{items?.due_amount}</td>
                                    {/* <td className="admin_user_list_date">{moment(items?.createdAt).format('DD-MM-YYY h:mm A')}</td> */}
                                    <td className="admin_user_list_date">{moment(items?.updatedAt).format('DD-MM-YYY h:mm A')}</td>
                                    <td>
                                      <Dropdown
                                        overlay={
                                          <Menu>
                                            <Menu.Item
                                              key={`01`}
                                              onClick={() => {
                                                setEditData(items)
                                                // toggleAddEditPopup();
                                              }}
                                            >
                                            <FontAwesomeIcon icon={faEdit} /> <span className="show_span">Edit</span>
                                            </Menu.Item>
                                          </Menu>
                                        }
                                        placement="bottomLeft"
                                        arrow
                                      >
                                          <FontAwesomeIcon icon={faEllipsis} />
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : isLoading ? <SkeltonList row={10} col={8} /> : <tr> 
                                <td colspan="8" className="img-center" >No data found !</td> 
                            </tr>}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination
                            count={TOTALPAGES}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </VendorList>
          </Box>
        </div>
      </div>
      {/* Add/Edit Pop-Up */}
      <AddEditDataPopUp popUpOpen={addEditPopup} togglePopUp={toggleAddEditPopup} edit_data={edit_data} setEditData={setEditData} />
      {/* Export*/}
      <ExportPopup popUpOpen={isExportShow} togglePopUp={handleExportDrawer} />
      <ImportPopup popUpOpen={isImportShow} togglePopUp={handleImportDrawer} />
      <ImportCollection popUpOpen={isImportCollectionShow} togglePopUp={handleImportCollection} />
      {/* Filter */}
      <Drawer
        title="Filter"
        placement={`right`}
        width={500}
        onClose={handleFiterDrawer}
        open={isFilterShow}
        extra={
          <Space>
            <Button onClick={handleFiterDrawer}>Cancel</Button>
            <Button type="primary" onClick={handleFilterReset}  style={{ backgroundColor: 'red', color: 'white' }}>
              Reset
            </Button>
          </Space>
        }
      >
        <div className="responsive">
          <div className="row">
            <form id='filter_form' onSubmit={handleFilterApply}>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  Search Field :
                </label>
                <select name="filter_by" className="form-control store_input_field" id="filter_by" ref={inputRef1}>
                  <option value=''>Select search field</option>
                  <option value='name' selected={filter?.filter_by ==='name'?true:false}>Name</option>
                  <option value='store' selected={filter?.filter_by ==='store'?true:false}>Store name</option>
                  <option value='users_mobile' selected={filter?.filter_by ==='users_mobile'?true:false}>Mobile</option>
                  <option value='due_amount' selected={filter?.filter_by ==='due_amount'?true:false}>Amount</option>
                  <option value='bind_person_name' selected={filter?.filter_by ==='bind_person_name'?true:false}>Bind with user's name</option>
                  <option value='bind_person_id' selected={filter?.filter_by ==='bind_person_id'?true:false}>Bind with user's id</option>
                </select>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  Search Text :
                </label>
                <input
                  title="Enter search text."
                  placeholder="Enter search text."
                  type="text"
                  name="search"
                  className="form-control store_input_field"
                  id="search"  
                  ref={inputRef2}
                />
              </div>
              <br/>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  title="Enter search text."
                  type="submit"
                  className="form-control btn btn-black"    
                  style={{ backgroundColor: "black", color: "white" }}           
                >Apply</button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
}
