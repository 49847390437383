import React, { useEffect, useState,useCallback, useRef } from "react";
import { debounce } from "lodash";
import { notification, Select } from "antd";
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";

import LoadingEffect from "../../../../components/Loading/LoadingEffect";

import {userList,createRequest}  from  "../../../../controllers/V1/invoice/invoiceControllers";

const UserListEditPage = () => {
  
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [error, setError] = useState([]);
  const [today] = useState(new Date().toISOString().split("T")[0]);
  const [ADDEDITDATA, setAddEditSata] = useState({
    start_time : '21:31:00',
    end_time : '21:30:00'
  });
  const [ALLUSERSLIST, setAllUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [OPTIONS, setOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [search_by, setSearchBy] = useState('');
  const handleSearchBy = (e)=>setSearchBy(e.target.value);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Loading, please wait...');

  const [invoiceData, setInvoiceData] = useState({});

  const [retailerList, setRetailerList] = useState([]);

  const [selectAll, setSelectAll] = useState(false);
  const toggleSelectAll = ()=>setSelectAll(!selectAll);

  /*********************************************************
   *  This function is use to handle imput chnage
   *********************************************************/
  const handleChange = (e) => {
    setAddEditSata((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setError((pre) => ({
      ...pre,
      [e.target.name]: "",
    }));
  };

  const invoiceDataChange = (e) => {
    setInvoiceData((pre)=>({
      ...pre,
      [e.target.name] : e.target.value 
    }))
  }; 
  
  /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData(e?.target);
      let msg = "";
      let status = false;
      let updatedRetailerList = [];
      let batch_id = '';
      if(retailerList && retailerList.length > 0){
        updatedRetailerList = retailerList.filter((retailer) => retailer?.selected === true);
      }
      
      if(!selectedUser._id || !selectedUser.users_id){
        msg = 'Please select the Sales Person.';
      }else if(!formData.get('start_date') || !formData.get('start_time')){
        msg = 'From date and time is required.';
      }else if(!formData.get('end_date') || !formData.get('end_time')){
        msg = 'To date and time is required.';
      }else if(!formData.get('store_name')){
        msg = 'Store name is required.';
      }else if(!formData.get('user_name')){
        msg = 'Name is required.';
      }else if(!formData.get('mobile')){
        msg = 'Mobile is required.';
      }else if(!formData.get('invoice_date')){
        msg = 'Invoice date is required.';
      }else if(!formData.get('payment_mode')){
        msg = 'Mode/Terms of payment is required.';
      }else if(updatedRetailerList?.length === 0){
        msg = 'Please select customer/retailer.';
      }else{

        const start_date = `${formData.get('start_date')} ${formData.get('start_time')}`;
        const end_date = `${formData.get('end_date')} ${formData.get('end_time')}`;
        
        const params = {
          users_oid     : selectedUser._id,
          users_id      : selectedUser.users_id,
          store         : formData.get('store_name'),
          users_name    : formData.get('user_name'),
          users_mobile  : formData.get('mobile'),
          invoice_date  : formData.get('invoice_date'),
          payment_mode  : formData.get('payment_mode'),
          start_date    : start_date,
          end_date      : end_date,

          start_time    : `${formData.get('start_time')}`,
          end_time      : `${formData.get('end_time')}`,

             
          retailerList  : updatedRetailerList,
          invoice_number: updatedRetailerList?.length?updatedRetailerList?.length:0

        }
        const res = await createRequest(params);
        if(res.status === true){
          batch_id = res?.result?.batch_no
          status = true;
          msg = "Request sent successfully."
        } else{
          status = false;
          msg = res.message
        }
      }
      if(status === true){
        notification.open({
          message: "Success.",
          description: `${msg}`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });

        navigate(`/invoice/retailers-list/${batch_id}`);
      } else {
        notification.open({
          message: "Oops!.",
          description: `${msg}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const getUsersData = async (searchText='') =>{
    try {
      setSelectedUser([]);
      let searchString = searchText;
      let store_name, users_name, users_mobile, users_email, pos_number, bind_person_name;

      if(search_by === 'store_name'){
        store_name = { $regex: searchString, $options: "i" };
      } else if(search_by === 'users_name'){
        users_name = { $regex: searchString, $options: "i" };
      } else if(search_by === 'users_mobile'){
        users_mobile = parseInt(searchText);
      } else if(search_by === 'users_email'){
        users_email = { $regex: searchString, $options: "i" };
      } 

      const option = {
        condition : { 
          status : "A",
          ...(store_name?{store_name:store_name}:null),
          ...(users_name?{users_name:users_name}:null),
          ...(users_mobile?{users_mobile:users_mobile}:null),
          ...(users_email?{users_email:users_email}:null)
        },
        select : { users_type : true, users_name : true, last_name : true, users_mobile : true, users_email : true, users_mobile : true, store_name : true, commission_percentage : true, users_id : true, pos_device_id : true, pos_number : true, area : true, bind_person_name : true },
        sort : {store_name : 1},
        limit : 50
      }
      const res = await userList(option);
      if(res.status === true){
        setAllUsersList(res?.result || []);
      }
      setOptionsLoading(false);
    } catch (error) {
      
    }
  }

  useEffect(() => {
    document.title = `CRM U-Winn || Create Request`;
  }, []);

  const debounceTimeout = useRef(null);

  const handleSearch = (value) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      if (value.trim() !== "" && value.length > 3) {
        getUsersData(value);
      }
    }, 500); 
  };

  useEffect(()=>{
    if(ALLUSERSLIST.length > 0){
      const transformedArray = ALLUSERSLIST.map(user => ({
        label: `${user.users_name} | ${user.store_name} | ${user.users_mobile}`,
        value: JSON.stringify(user) 
      }));
      setOptions(transformedArray);
    }
  },[ALLUSERSLIST]);

  const handleUserChange = (value) =>{
    try {
      const userData = JSON.parse(value);
      setSelectedUser(userData);
      setInvoiceData((pre)=>({
        ...pre,
        store_name : userData?.store_name,
        user_name : `${userData?.users_name} ${userData?.last_name}`,
        mobile : userData?.users_mobile
      }))
    } catch (error) {
      
    }
  }

  const getRetailerList = async () => {
    try {
      const option = {
        type : 'multiple',
        condition : { bind_person_id : selectedUser?.users_id.toString() },
        select : { store_name : true, users_name : true, users_mobile : true, users_id : true, pos_number : true, bind_person_name : true, area : true },
        skip : 0,
        limit : 0
      }
      const res = await userList(option);
      if(res.status === true){
        setRetailerList(res.result);
      }else{
        setRetailerList([]);
      } 
    } catch (error) {
      setRetailerList([]);
    }
  }
  const handleSelectRow = (id) => {
    setRetailerList((prev) =>
      prev.map((item) =>
        item._id === id ? { ...item, selected: !item.selected } : item
      )
    );
  }

  useEffect(()=>{
    // if(selectAll === true){
      setRetailerList((prev) =>
        prev.map((item) =>
          item._id?{ ...item, selected: selectAll } : item
        )
      );
    // }
  },[selectAll])

  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="Inventory_edit_page_main_div">
          <React.Fragment>
            <h5 className="inventory_edit_page_heading"> Generate Invoice </h5>
          </React.Fragment>
          <Link to="/invoice/request">
            <React.Fragment>
              <button type="button" className="btn btn-secondary"> <ArrowLeftOutlined /> Back </button>
            </React.Fragment>
          </Link>
        </div>

        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-body">
            <div className="responsive">
              <form method="post" className="inventory_form_main_contnet" onSubmit={handleSubmit}>
                <div className="row">
                  {/* Search By */}
                  <div className="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12 vendor_Add_edit_data">
                    <label htmlFor="search_by" className="all_section_for_label_main_class" >
                      <span style={{ color: "red" }}>*</span>Customer/Retailer's store :{" "}
                    </label>
                    <select title="Choose your option" name="search_by" id="search_by" className="form-control store_input_field" onChange={handleSearchBy}>
                      <option value="" selected disabled>Choose your option</option>
                      <option value="store_name">Store name</option>
                      <option value="users_name">users name</option>
                      <option value="users_mobile">users mobile</option>
                      <option value="users_email">users email</option>
                    </select>
                  </div>

                  <div className="form-group col-lg-9 col-md-9 col-sm-12 col-xs-12 vendor_Add_edit_data">
                    <label htmlFor="admin_first_name" className="all_section_for_label_main_class" >
                      <span style={{ color: "red" }}>*</span>Customer/Retailer :{" "}
                    </label>
                    <Select
                      className="form-control"
                      onSearch={handleSearch}
                      onChange={handleUserChange}
                      showSearch={true}
                      defaultOpen={true}
                      autoClearSearchValue={false}
                      placeholder="Search to Select"
                      notFoundContent={null}
                      loading={optionsLoading}
                      options={OPTIONS}
                    />
                    {error.admin_first_name ? (
                      <p style={{ color: "red" }}>{error.admin_first_name}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                    <label
                      htmlFor="start_date"
                      className="all_section_for_label_main_class"
                    >
                      <span style={{ color: "red" }}>*</span>From :{" "}
                    </label>
                    <input
                      type="date"
                      name="start_date"
                      max={today}
                      className="form-control store_input_field"
                      id="start_date"
                      onChange={handleChange}
                    />
                    {error.start_date ? (
                      <p style={{ color: "red" }}>{error.start_date}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                    <label
                      htmlFor="start_time"
                      className="all_section_for_label_main_class"
                    >
                      <span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    <input
                      type="time"
                      name="start_time"
                      className="form-control store_input_field"
                      id="start_time"
                      value={ADDEDITDATA?.start_time}
                      onChange={handleChange}
                    />
                    {error.start_time ? (
                      <p style={{ color: "red" }}>{error.start_time}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group col-lg-3 col-md-3 col-sm-16 col-xs-12 vendor_Add_edit_data">
                    <label
                      htmlFor="end_date"
                      className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                    >
                      <span style={{ color: "red" }}>*</span>To :
                    </label> 
                    <input
                      type="date"
                      name="end_date"
                      className="form-control store_input_field"
                      id="end_date"
                      max={today}
                      onChange={handleChange}
                    />   
                    {error.end_date ? (
                      <p style={{ color: "red" }}>{error.end_date}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                    <label className="all_section_for_label_main_class" >
                      <span style={{ color: "red" }}>*</span>{" "}
                    </label>
                    <input
                      type="time"
                      name="end_time"
                      className="form-control store_input_field"
                      id="end_time"
                      value={ADDEDITDATA?.end_time}
                      onChange={handleChange}
                    />
                    {error.end_time ? (
                      <p style={{ color: "red" }}>{error.end_time}</p>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* Store Name */}
                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                    <label htmlFor="admin_first_name" className="all_section_for_label_main_class" >
                      <span style={{ color: "red" }}>*</span>Customer/Retailer's store :{" "}
                    </label>
                    <input
                      title="Customer/Retailer's Store."
                      placeholder="Customer/Retailer's store"
                      type="text"
                      name="store_name"
                      className="form-control store_input_field"
                      id="store_name"
                      value={invoiceData?.store_name || ''}
                      onChange={invoiceDataChange}
                    />
                    {error.store_name ? ( <p style={{ color: "red" }}>{error.store_name}</p> ) : ( "" )}
                  </div>
                  
                  {/* User's Name */}
                  <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 vendor_Add_edit_data">
                    <label htmlFor="user_name" className="all_section_for_label_main_class" >
                      <span style={{ color: "red" }}>*</span>Name :{" "}
                    </label>
                    <input
                      title="User's Name."
                      placeholder="Customer/Retailer's name"
                      type="text"
                      name="user_name"
                      className="form-control store_input_field"
                      id="user_name"
                      value={invoiceData?.user_name || ''}
                      onChange={invoiceDataChange}
                    />
                    {error.user_name ? ( <p style={{ color: "red" }}>{error.user_name}</p> ) : ( "" )}
                  </div>
                  {/* User's Mobile */}
                  <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 vendor_Add_edit_data">
                    <label htmlFor="mobile" className="all_section_for_label_main_class" >
                      <span style={{ color: "red" }}>*</span>Mobile no. :{" "}
                    </label>
                    <input
                      title="Mobile No."
                      placeholder="Mobile No."
                      type="text"
                      name="mobile"
                      className="form-control store_input_field"
                      id="mobile"
                      value={invoiceData?.mobile}
                      onChange={invoiceDataChange}
                    />
                    {error.mobile ? ( <p style={{ color: "red" }}>{error.mobile}</p> ) : ( "" )}
                  </div>
                  
                  {/* Invoice Date */}
                  <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                    <label htmlFor="invoice_date" className="all_section_for_label_main_class" ><span style={{ color: "red" }}>*</span>Invoice date :</label>
                    <input
                      title="Invoice Date"
                      placeholder="Invoice Date"
                      type="date"
                      name="invoice_date"
                      className="form-control store_input_field"
                      id="invoice_date"
                      value={invoiceData?.invoice_date || ''}
                      onChange={invoiceDataChange}
                    />
                    {error.invoice_date ? ( <p style={{ color: "red" }}>{error.invoice_date}</p> ) : ( "" )}
                  </div>
                  {/* payment mode */}
                  <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                    <label htmlFor="payment_mode" className="all_section_for_label_main_class" >
                      <span style={{ color: "red" }}>*</span>Mode/Terms of payment :{" "}
                    </label>
                    <select name="payment_mode" id="payment_mode" className="form-control store_input_field" onChange={invoiceDataChange} >
                      <option value='' selected disabled >Choose your options</option>
                      <option value='Cash' >Cash</option>
                      <option value='Online' >Online</option>
                    </select>
                    {error.payment_mode ? ( <p style={{ color: "red" }}>{error.payment_mode}</p> ) : ( "" )}
                  </div>
                </div>

                <div className="inventory_edit_submit_btn">
                  <button type="button" className="btn btn-primary inventory_save_button" onClick={getRetailerList} > Get Retailer's List </button>
                </div>
                <div className="row">
                  <div className="table-responsive">
                    <table
                      className="table table-bordered"
                      id="dataTable"
                      width="100%"
                      cellspacing="0"
                    >
                      <thead>
                        <tr>
                          <th onClick={toggleSelectAll} ><input type="checkbox" checked={selectAll} className="store_input_field"/> Sl. No.</th>
                          <th>Customer/Retailer</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                      {retailerList?.length > 0 ? (
                          retailerList.map((items, index) => (
                            <>
                              <tr key={index} onClick={()=>handleSelectRow(items._id)}>
                                <td>
                                  {index + 1} {"  "}
                                  <input type="checkbox" checked={items?.selected === true?true:false} className="store_input_field"/>
                                </td>

                                <td className="admin_user_list_date"> 
                                  <span>Store : {items?.store_name}</span><br/>
                                  <span>Name : {items?.users_name}</span><br/> 
                                  <span>Mobile : {items?.users_mobile}</span>
                                </td>

                                <td className="admin_user_list_date"> Pending </td>
                              </tr>
                            </>
                          ))
                        ) : isLoading ? <></> : <tr> 
                            <td colspan="3" className="img-center" >No data found !</td> 
                        </tr>}
                      </tbody>
                    </table>
                    <div className="inventory_edit_submit_btn">
                      <button type="submit" className="btn btn-primary inventory_save_button"> Generate Request </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <LoadingEffect isLoading={isLoading} text={loadingText} />
    </div>
  );
};

export default UserListEditPage;
