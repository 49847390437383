module.exports = {
    API_KEY : "d42a0d190464a2be90977c3996382811",
    
    // API_ADMIN_URL : "http://localhost:3300/v1/admin/",
    // API_BASE_URL : "http://localhost:3300/",
    
    // ASSETS_BASE_URL : "http://localhost:3300/",

    API_ADMIN_URL : "https://crm.u-winn.com/v1/admin/",
    API_BASE_URL : "https://crm.u-winn.com/",
    
    ASSETS_BASE_URL : "https://crm.u-winn.com/",

}