import React, { useEffect, useState } from "react";
import "./App.css";
import LoginPage from "./components/LoginPage/LoginPage.js";
import ResetPassword from "./components/LoginPage/ResetPassword.js";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./components/SideBar/Sidebar.jsx";
import Dashboard from "./pages/DashBaord/Dashboard.jsx";
import SubAdminList from './pages/Accounts/SubAdmin/List.js'
import SubAdminAddEditData from './pages/Accounts/SubAdmin/AddEditData.js'
import { fetchIpAddress } from "./controllers/API"

import InvoiceList from "./pages/v1/invoice/List.js";
import InvoiceAddEditData from "./pages/v1/invoice/AddEditData.js";
import InvoicePreview from "./pages/v1/invoice/preview.js";
import BulkList from './pages/v1/invoice/bulk/List.js'
import BulkRequest from './pages/v1/invoice/bulk/CreateRequest.js'
import RetailersList from './pages/v1/invoice/bulk/RetailersList.js'
import BulkInvoicePreview from './pages/v1/invoice/bulk/BulkPreview.js'
import DownloadAllInvoice from './pages/v1/invoice/bulk/DownloadInvoice.jsx'

import CustomerDueList from './pages/v1/customer_due/List.js'

// import { useUserStore } from "./lib/userStore.js";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const { fetchUserInfo } = useUserStore();

  const getIp = async () => {
    await fetchIpAddress();
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      await getIp();
      const token = sessionStorage.getItem("TOKEN");

      if (!["/", "/login", "/reset-password","/invoice/preview"].includes(location?.pathname)) {
        if (!token) {
          navigate("/");
        } else {
          // fetchUserInfo();
        }
      }
    };

    checkAuthentication();
  }, [location, navigate]);

  const [PERMISSIONDATA, setPermissionData] = useState('');
  const user = JSON.parse(sessionStorage.getItem("ADMIN-INFO"));
  useEffect(()=>{
    if(sessionStorage.getItem("ADMIN-PERMISSION") !== 'undefined'){
      const permission = JSON.parse(sessionStorage.getItem("ADMIN-PERMISSION"));
      setPermissionData(permission);
    }
  },[])


  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/dashboard" element={ <Sidebar> <Dashboard /> </Sidebar> } />
          {(user?.admin_type === 'Super Admin') && (<>
            <Route path="/sub-admin/list" element={ <Sidebar> <SubAdminList /> </Sidebar> } />
          </>)}

          <Route path="/sub-admin/addeditdata" element={ <Sidebar> <SubAdminAddEditData /> </Sidebar> } />
          
          {(PERMISSIONDATA?.invoice?.individual === 'Y' || user?.admin_type === 'Super Admin') && (<>
            <Route path="/invoice/list" element={ <Sidebar> <InvoiceList /> </Sidebar> } />
            <Route path="/invoice/addeditdata" element={ <Sidebar> <InvoiceAddEditData /> </Sidebar> } />
          </>)}

          <Route path="/invoice/preview" element={ <InvoicePreview /> } />
          {(PERMISSIONDATA?.invoice?.bulk_request === 'Y' || user?.admin_type === 'Super Admin') && (<>
            <Route path="/invoice/request" element={<Sidebar><BulkList/></Sidebar>} />
            <Route path="/invoice/create-request" element={<Sidebar><BulkRequest/></Sidebar>} />
            <Route path="/invoice/retailers-list/:batch_id" element={<Sidebar><RetailersList/></Sidebar>} />
            <Route path="/invoice/bulk-preview/:batch_id" element={<BulkInvoicePreview/>}/>
            <Route path="/invoice/download-all" element={<Sidebar><DownloadAllInvoice/></Sidebar>}/>
          </>)}
          
          {(PERMISSIONDATA?.customer_due?.customer_list === 'Y' || user?.admin_type === 'Super Admin') && (<>
            <Route path="/customers-due/list" element={<Sidebar><CustomerDueList/></Sidebar>}/>
          </>)}

        </Routes>
      
    </div>
  );
};

export default App;
