import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Dropdown, Menu, notification, Drawer, Button, Space } from "antd";
import { faFilter, faEdit, faEye, faTrash, faDownload } from '@fortawesome/free-solid-svg-icons';
import Pagination from "@mui/material/Pagination";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';

import TobNavBar from "../../DashBaord/Top_navbar";
// import VendorListDeletePopUp from "./DeletePopUp";
import { Link, useNavigate } from "react-router-dom";

import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  LikeOutlined,
  DislikeOutlined,
} from "@ant-design/icons";

import moment from "moment";
import SkeltonList from "../../../components/SkeltonEffect/list";
import {
  list,
  deleteInvoice,
} from "../../../controllers/V1/invoice/invoiceControllers";
import { getPage, getRelativeTime } from "../../../controllers/common";

// import { FaPlusSquare } from "react-icons/fa";
import {API_BASE_URL} from "../../../config/constants";

function VendorList(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

VendorList.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [isFilterShow, setFilterShow] = useState(false);
  const handleFiterDrawer = () =>  setFilterShow(!isFilterShow); 

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [helpText, setHelpText] = useState('');
  const handleSearchByChange = (e) => {
    try {
      const {value} = e.target;
      if(value === 'invoice_date'){
        setHelpText('Format should be YYYY-MM-DD. (e:g 2024-12-25)');
      } else if(value === 'payment_mode'){
        setHelpText('Enter Cash/Online only.');
      } else{
        setHelpText('');
      }
    } catch (error) {
      
    }
  }

  /************************************************************************************* */
  const [isLoading, setIsLoading] =useState(true);
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [ALLLISTDATA, setListData] = useState([]);
  const [TOTALPAGES, setTotalPages] = useState(1);
  const [LIMIT, setLimit] = useState(10);
  const [SKIP, setSkip] = useState(0);
  const [filter, setFilter] = useState({
    from: "",
    to: "",
    filter_by: "",
    search: "",
  }); //End

  const [showRequest, setShowRequest] = useState("");

  const handleEdit = async (item = {}) => {
    navigate("/invoice/addeditdata", { state: item });
  };
  /*********************************************************
   *  This function is use to fetch vendor list
   *********************************************************/
  const getList = async () => {
    try {
      setIsLoading(true);
      setListData([]);

      const options = {
        type: "",
        condition: {
          ...(filter.filter_by === 'users_name'?{ users_name : { $regex: filter?.search, $options: "i" } }:null),
          ...(filter.filter_by === 'store'?{store : { $regex: filter?.search, $options: "i" } }:null),
          ...(filter.filter_by === 'users_mobile'?{ users_mobile : parseInt(filter?.search) }:null),
          ...(filter.filter_by === 'pos_number'?{ pos_number : parseInt(filter?.search) }:null),
          ...(filter.filter_by === 'invoice_no'?{ invoice_no : parseInt(filter?.search) }:null),
          ...(filter.filter_by === 'sales_person_name'?{ sales_person_name : { $regex: filter?.search, $options: "i" } }:null),
          ...(filter.filter_by === 'payment_mode'?{ payment_mode : { $regex: filter?.search, $options: "i" } }:null),
          ...(filter.filter_by === 'batch_no'?{ batch_no : parseInt(filter?.search) }:null),
          ...(filter.filter_by === 'status'?{status : { $regex: filter?.search, $options: "i" }}:null),
          ...(filter.filter_by === 'sub_total_due'?{sub_total_due : parseFloat(filter?.search) }:null),
          ...(showRequest ? { status: showRequest } : null),
          
          ...(filter.filter_by === 'invoice_date'?{ invoice_date : {$gte : new Date(`${filter?.search} 0:01:00`), $lte : new Date(`${filter?.search} 23:59:00`)} }:null),

          ...(filter?.from?{start_date : {$gte : new Date(`${filter.from} 0:01:00`), $lte : new Date(`${filter.from} 23:59:00`)} }:null),
          ...(filter?.to?{end_date : {$gte : new Date(`${filter.to} 0:01:00`), $lte : new Date(`${filter.to} 23:59:00`)} }:null),
        },
        select: {},
        sort: { _id: -1 },
        skip: SKIP ? SKIP : 0,
        limit: LIMIT ? LIMIT : 10,
      };
      const listData = await list(options);
      if (listData.status === true) {
        if (listData.result.length > 0) {
          setListData(listData?.result);
          setTotalPages(getPage(listData?.count))
        } else {
          setListData([]);
          setTotalPages(getPage(1))
        }
      } else {
        setListData([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }; //End

  /*********************************************************
   *  This function is for handle page change
   *********************************************************/
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    setSkip((newPage - 1) * LIMIT);
    targetRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };
 

  /*********************************************************
   *  This function is use to handle remove invoice
   *********************************************************/
  const handleRemove = async (id='') => {
    try {
      if(!id){
        notification.open({
          message: "Oops!",
          description: `Invoice id is required.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }else{
        const res = await deleteInvoice(id);
        if (res.status === true) {
          notification.open({
            message: "Success",
            description: `Data deleted successfully.`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
        } else {
          notification.open({
            message: "Oops!",
            description: `${res?.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      }
    } catch (error) {
      notification.open({
        message: "Oops!",
        description: `Operation not perform yet! please try in some time.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
    getList();
  };

  const handlePDFDownload = async (invoiceData) => {
    try {  
      const {
        store,users_name,pos_number,users_mobile,sales_person_name,invoice_date,invoice_no,payment_mode,start_date,end_date,start_time,end_time,area,
        lottoTotalSales, lottoTotalCommission, lottoPaidAmount, lottoIncentives,
        raffleTotalSales, raffleTotalCommission, rafflePaidAmount, raffleIncentives,
        pointsTotalSales, pointsTotalCommission, pointsPaidAmount, pointsIncentives,

        total_sales,total_commission,total_paid,total_due,other_charges,old_pending_balance,sub_total_due,total_incentives,

        } = invoiceData;
      const isValidate = true
      if(!isValidate){
        alert('Incomplete invoice data');
      }else{
        // const previewURL = `/invoice/preview?store_name=${store}&user_name=${users_name}&pos_number=${pos_number}&mobile=${users_mobile}&totalSales=${total_sales}&totalCommission=${total_commission}&paidAmount=${total_paid}&totalDue=${total_due}&other_charges=${other_charges}&old_balance=${old_pending_balance}&subTotalDue=${sub_total_due}&incentives=${total_incentives}&invoice_date=${invoice_date}&invoice_no=${invoice_no}&payment_mode=${payment_mode}&sales_person_name=${sales_person_name}&start_date=${start_date}&end_date=${end_date}&area=${area}`;
        // window.open(previewURL, '_blank');
        let previewURL = `/invoice/preview?`
        previewURL    +=  `autoDownload=Y`
        previewURL    +=  `&store_name=${store}`
        previewURL    +=  `&user_name=${users_name}`
        previewURL    +=  `&pos_number=${pos_number}`
        previewURL    +=  `&mobile=${users_mobile}`
        previewURL    +=  `&totalSales=${total_sales}`
        previewURL    +=  `&totalCommission=${total_commission || 0}`
        previewURL    +=  `&paidAmount=${total_paid || 0}`
        previewURL    +=  `&totalDue=${total_due || 0}`
        previewURL    +=  `&other_charges=${other_charges || 0}`
        previewURL    +=  `&old_balance=${old_pending_balance || 0}`
        previewURL    +=  `&subTotalDue=${sub_total_due || 0}`
        previewURL    +=  `&incentives=${total_incentives || 0}`
        previewURL    +=  `&invoice_date=${invoice_date}`
        previewURL    +=  `&invoice_no=${invoice_no}`
        previewURL    +=  `&payment_mode=${payment_mode}`
        previewURL    +=  `&start_date=${start_date}`
        previewURL    +=  `&start_time=${start_time}`
        previewURL    +=  `&end_date=${end_date}`
        previewURL    +=  `&end_time=${end_time}`
        previewURL    +=  `&sales_person_name=${sales_person_name}`
        previewURL    +=  `&area=${area}`

        previewURL    +=  `&lottoTotalSales=${lottoTotalSales || 0}`
        previewURL    +=  `&lottoTotalCommission=${lottoTotalCommission || 0}`
        previewURL    +=  `&lottoPaidAmount=${lottoPaidAmount || 0}`
        previewURL    +=  `&lottoIncentives=${lottoIncentives || 0}`

        previewURL    +=  `&raffleTotalSales=${raffleTotalSales || 0}`
        previewURL    +=  `&raffleTotalCommission=${raffleTotalCommission || 0}`
        previewURL    +=  `&rafflePaidAmount=${rafflePaidAmount || 0}`
        previewURL    +=  `&raffleIncentives=${raffleIncentives || 0}`

        previewURL    +=  `&pointsTotalSales=${pointsTotalSales || 0}`
        previewURL    +=  `&pointsTotalCommission=${pointsTotalCommission || 0}`
        previewURL    +=  `&pointsPaidAmount=${pointsPaidAmount || 0}`
        previewURL    +=  `&pointsIncentives=${pointsIncentives || 0}`

        
        window.open(previewURL, '_blank');
      }
    } catch (error) { 
      console.log('error', error)
    }
  }
  
  const dropdownMenu = (items) => {
    return (
      <>
        <Menu>
          <Menu.Item key="01"onClick={()=>handlePDFDownload(items)}>
          <FontAwesomeIcon icon={faEye} />
            <span className="show_span_edit">PDF</span>
          </Menu.Item>
          <Menu.Item key={`02`} onClick={() => { handleRemove(items._id); }} >
           <FontAwesomeIcon icon={faTrash} /> <span className="show_span">Remove</span>
          </Menu.Item>
        </Menu>
      </>
    );
  };

  /*********************************************************
   *  This function is ued for handle filter input change
   *********************************************************/
  const handleFilterReset = () => {
    try {
      setFilter('');
      inputRef1.current.value = '';
      inputRef2.current.value = '';
      inputRef3.current.value = '';
      inputRef4.current.value = '';
    } catch (error) {
      
    }
  }

  const handleFilterApply = (e) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      setFilter((pre)=>({
        ...pre,
        'filter_by' : form.get('filter_by') || '',
        'search'    : form.get('search') || '',
        'to'        : form.get('to_date') || '',
        'from'      : form.get('from_date') || '',

      }))
      // console.log('filter',filter);
      // alert('submit');
      handleFiterDrawer();
      setSkip(0);
    } catch (error) {
      
    }
  }

  /*********************************************************
   *  This function will load when page load and with dependency update
   *********************************************************/
  useEffect(() => {
    getList();
    targetRef.current.scrollIntoView({
      behavior:'smooth',
    });
    document.title = "U-Winn CRM || Request List";
  }, [currentPage, showRequest, filter]);

  return (
    <>
      <div className="container-fluid" ref={targetRef}>
        <TobNavBar title={"Invoice List"}></TobNavBar>
        <div className="inventory_tabs_main_div">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="inventory_all_tabs">
                <div className="all_tabs_value">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="item_tabs_main_container"
                  >
                    <Tab
                      label="All"
                      className="tabs_main_contnet"
                      {...a11yProps(0)}
                      onClick={() => setShowRequest("")}
                    />
                  </Tabs>
                </div>

                <div className="fa_filter_rating_review filter_img_and_add_btn_main">
                  <div className="inventory_filter_div" onClick={handleFiterDrawer}>
                  <FontAwesomeIcon icon={faFilter} />
                    <Link
                      to={false}
                      className="filter_btn_invontry_rating_review"
                    >
                      Filters
                    </Link>
                  </div>
                  <div onClick={() => handleEdit()}>
                    <button type="button" class="btn btn-dark">
                    <FontAwesomeIcon icon="fa-light fa-plus Promotions_faPlus_Squares" />
                   
                      <span className="request_new_categories">Add New</span>
                    </button>
                  </div>
                </div>
              </div>
            </Box>            
            <VendorList 
              >
              <div>
                <div id="content-wrapper" className="d-flex flex-column">
                  <div className="card shadow mb-4">
                    <div className="card-body pt-0">
                      <div className="table-responsive">
                        <table
                          className="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                        >
                          <thead>
                            <tr>
                              <th>Invoice No.</th>
                              <th>POS Number.</th>
                              <th>Customer's Details</th>
                              <th>Amount</th>
                              <th>Period</th>
                              <th>Due Date</th>
                              <th>Invoice Date</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ALLLISTDATA?.length > 0 ? (
                              ALLLISTDATA.map((items, index) => (
                                <>
                                  <tr key={index}>
                                    <td>{items?.invoice_no}</td>
                                    <td>{items?.pos_number}</td>
                                    <td className="admin_user_list_date"> 
                                      <span>Store : {items?.store}</span><br/>
                                      <span>Name : {items?.users_name}</span><br/> 
                                      <span>Mobile : {items?.users_mobile}</span><br/>
                                      <span>Bind With : {items?.sales_person_name}</span>
                                    </td>
                                    <td className="admin_user_list_date">{items?.total_due?.toFixed(2)}</td>
                                    {/* <td className="admin_user_list_date">
                                      From : {moment(`${items?.start_date}`).subtract(0, 'hours').format("DD-MM-YYYY, hh:mm A")} 
                                      <br/>
                                      To : {moment(`${items?.end_date}`).subtract(0, 'hours').format("DD-MM-YYYY, hh:mm A")} 
                                    </td> */}
                                    <td className="admin_user_list_date">
                                      From : 
                                        {`
                                          ${moment(`${items?.start_date}`).subtract(4, 'hours').format("DD-MM-YYYY")}
                                          ${moment(`2025-03-03 ${items?.start_time}`).format("hh:mm A")}
                                        `} 
                                      <br/>
                                      To : 
                                        {`
                                          ${moment(`${items?.end_date}`).subtract(4, 'hours').format("DD-MM-YYYY")}
                                          ${moment(`2025-03-03 ${items?.end_time}`).format("hh:mm A")}
                                        `} 
                                    </td>
                                    {items?.due_date?(
                                      <td className="admin_user_list_date">
                                        <h6 dangerouslySetInnerHTML={{ __html: getRelativeTime(items?.due_date) }}></h6>
                                        {moment(`${items?.due_date}`).format("DD-MM-YYYY")}
                                      </td>

                                    ):(
                                      <td className="admin_user_list_date">-</td>
                                    )}
                                      
                                    <td className="admin_user_list_date">{moment(`${items?.invoice_date}`).format("DD-MM-YYYY")}</td>
                                    <td className="admin_user_list_date">{items?.status}</td>
                                    <td>
                                      <Dropdown
                                        overlay={() => dropdownMenu(items)}
                                        placement="bottomLeft"
                                        arrow
                                      >
                                          <FontAwesomeIcon icon={faEllipsis} />
                                      </Dropdown>
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : isLoading ? <SkeltonList row={10} col={9} /> : <tr> 
                                <td colspan="9" className="img-center" >No data found !</td> 
                            </tr>}
                          </tbody>
                        </table>
                        <div className="table_pagination_sales_order">
                          <Pagination
                            count={TOTALPAGES}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </VendorList>
          </Box>
        </div>
      </div>
      {/* Filter */}
      <Drawer
        title="Filter"
        placement={`right`}
        width={500}
        onClose={handleFiterDrawer}
        open={isFilterShow}
        extra={
          <Space>
            <Button onClick={handleFiterDrawer}>Cancel</Button>
            <Button type="primary" onClick={handleFilterReset}  style={{ backgroundColor: 'red', color: 'white' }}>
              Reset
            </Button>
          </Space>
        }
      >
        <div className="responsive">
          <div className="row">
            <form id='filter_form' onSubmit={handleFilterApply}>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="filter_by" className="all_section_for_label_main_class" >
                  Search Field :
                </label>
                <select name="filter_by" className="form-control store_input_field" onChange={handleSearchByChange} id="filter_by" ref={inputRef1}>
                  <option value=''>Select search field</option>
                  <option value='users_name' selected={filter?.filter_by ==='users_name'?true:false}>Name</option>
                  <option value='users_mobile' selected={filter?.filter_by ==='users_mobile'?true:false}>Mobile</option>
                  <option value='store' selected={filter?.filter_by ==='store'?true:false}>Store</option>
                  <option value='pos_number' selected={filter?.filter_by ==='pos_number'?true:false}>POS Number</option>
                  <option value='sales_person_name' selected={filter?.filter_by ==='sales_person_name'?true:false}>Sales Person Name</option>
                  <option value='payment_mode' selected={filter?.filter_by ==='payment_mode'?true:false}>Payment</option>
                  <option value='batch_no' selected={filter?.filter_by ==='batch_no'?true:false}>Batch Number</option>
                  <option value='invoice_no' selected={filter?.filter_by ==='invoice_no'?true:false}>Invoice Number</option>
                  <option value='invoice_date' selected={filter?.filter_by ==='invoice_date'?true:false}>Invoice Date</option>
                  <option value='sub_total_due' selected={filter?.filter_by ==='sub_total_due'?true:false}>Amount</option>
                  <option value='status' selected={filter?.filter_by ==='status'?true:false}>Status</option>
                </select>
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="search" className="all_section_for_label_main_class" >
                  Search Text :
                </label>
                <input
                  title="Enter search text."
                  placeholder="Enter search text."
                  type="text"
                  name="search"
                  className="form-control store_input_field"
                  id="search"  
                  ref={inputRef2}
                />
                {helpText?<label style={{color:'blue'}}>{helpText}</label>:''}
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  From :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="from_date"
                  className="form-control store_input_field"
                  id="from_date"      
                  ref={inputRef3}
                />
              </div>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <label htmlFor="vendor_first_name" className="all_section_for_label_main_class" >
                  To :
                </label>
                <input
                  title="Enter search text."
                  type="date"
                  name="to_date"
                  className="form-control store_input_field"
                  id="to_date"   
                  ref={inputRef4}
                />
              </div>
              <br/>
              <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  title="Enter search text."
                  type="submit"
                  className="form-control btn btn-black"    
                  style={{ backgroundColor: "black", color: "white" }}           
                >Apply</button>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </>
  );
}
