import React, { useEffect, useState,useCallback, useRef } from "react";
import { debounce } from "lodash";
import { Upload, Flex, Radio, notification, InputNumber, Select } from "antd";
import {
  InboxOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
// import "react-quill/dist/quill.snow.css";
import { Link, useLocation, useNavigate } from "react-router-dom";


import { fetchIpAddress } from "../../../controllers/API";
import { checkNumberValue } from "../../../controllers/common";

import LoadingEffect from "../../../components/Loading/LoadingEffect";

import {userList,getLoadBalanceData,createInvoice}  from  "../../../controllers/V1/invoice/invoiceControllers";
import {list} from "../../../controllers/V1/customerDue/dueController";
import {API_BASE_URL} from "../../../config/constants";

const UserListEditPage = () => {
  
  const location = useLocation();
  const navigate = useNavigate();
  // const { state } = location;
  const [error, setError] = useState([]);
  const [ADDEDITDATA, setAddEditSata] = useState({
    start_time : '21:31:00',
    end_time : '21:30:00'
  });
  const [ALLUSERSLIST, setAllUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [OPTIONS, setOptions] = useState([]);
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [search_by, setSearchBy] = useState('');
  const handleSearchBy = (e)=>setSearchBy(e.target.value);

  const [trnsactionHistory, setTransactionHistory] =useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Loading, please wait...');

  //Lotto 
  const [lottoTotalSalesAmount, setLottoTotalSalesAmount] = useState(0);
  const [lottoTotalCommissionAmount, setLottoTotalCommissionAmount] = useState(0);
  const [lottoTotalCancelAmount, setLottoTotalCancelAmount] = useState(0);
  const [lottoTotalPaidAmount, setLottoTotalPaidAmount] = useState(0);
  //End

  //Raffle 
  const [raffleTotalSalesAmount, setRaffleTotalSalesAmount] = useState(0);
  const [raffleTotalCommissionAmount, setRaffleTotalCommissionAmount] = useState(0);
  const [raffleTotalCancelAmount, setRaffleTotalCancelAmount] = useState(0);
  const [raffleTotalPaidAmount, setRaffleTotalPaidAmount] = useState(0);
  //End

  //Raffle 
  const [pointsTotalSalesAmount, setPointsTotalSalesAmount] = useState(0);
  const [pointsTotalCommissionAmount, setPointsTotalCommissionAmount] = useState(0);
  const [pointsTotalInsensitiveAmount, setPointsTotalInsensitiveAmount] = useState(0);
  const [pointsTotalCancelAmount, setPointsTotalCancelAmount] = useState(0);
  const [pointsTotalPaidAmount, setPointsTotalPaidAmount] = useState(0);
  //End

  const [totalSalesAmount, setTotalSalesAmount] = useState(0);
  const [totalCommissionAmount, setTotalCommissionAmount] = useState(0);
  const [totalCancelAmount, setTotalCancelAmount] = useState(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);

  const [totalDueAmount, setTotalDueAmount] = useState(0);
  const [subTotalDueAmount, setSubTotalDueAmount] = useState(0);

  const [invoiceData, setInvoiceData] = useState({});


  /*********************************************************
   *  This function is use to handle imput chnage
   *********************************************************/
  const handleChange = (e) => {
    setAddEditSata((pre) => ({
      ...pre,
      [e.target.name]: e.target.value,
    }));
    setError((pre) => ({
      ...pre,
      [e.target.name]: "",
    }));
  };

  const invoiceDataChange = (e) => {
    setInvoiceData((pre)=>({
      ...pre,
      [e.target.name] : e.target.value 
    }))
  } 

  const fetchUserDue = async () => {
    try {
      const option = {
        type : 'single',
        condition : { users_oid : selectedUser._id },
        select : {due_amount : true}
      }
      const res = await list(option);
      if(res.status === true){
        const due = res.result
        setInvoiceData((pre)=>({
          ...pre,
          old_balance : due?.due_amount
        }))
      } else{
        setInvoiceData((pre)=>({
          ...pre,
          old_balance : 0
        }))
      }
    } catch (error) {
      setInvoiceData((pre)=>({
        ...pre,
        old_balance : 0
      }))
    }
  }
  
  /*********************************************************
   *  This function is use to handle form submit
   *********************************************************/
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if(!selectedUser){
        alert('Please select user');
      } else if(!ADDEDITDATA.start_date || !ADDEDITDATA.start_time){
        alert('Please choose From date and time.');
      } else if(!ADDEDITDATA.end_date || !ADDEDITDATA.end_time){
        alert('Please choose To date and time.');
      } else{
        setIsLoading(true);
        setLoadingText('Fetching transaction history, Please wait...');
        // const startDate = new Date(`${ADDEDITDATA?.start_date} ${ADDEDITDATA?.start_time}`);
        // const endDate = new Date(`${ADDEDITDATA?.end_date} ${ADDEDITDATA?.end_time}`);
        const startDate = `${ADDEDITDATA?.start_date} ${ADDEDITDATA?.start_time}`;
        const endDate = `${ADDEDITDATA?.end_date} ${ADDEDITDATA?.end_time}`;
        const options = {
          condition : {
            $or : [
              {user_id_deb : parseInt(selectedUser?.users_id)},
              {user_id_cred : parseInt(selectedUser?.users_id)}
            ],
            created_at : { $gte : startDate, $lte : endDate }
          }
        }
        const res = await getLoadBalanceData(options);
        setInvoiceData((pre)=>({
          ...pre,
          user_oid : selectedUser?._id,
          user_id : selectedUser?.users_id,
          store_name : selectedUser?.store_name,
          user_name : `${selectedUser?.users_name}`,
          mobile : selectedUser?.users_mobile,
          email : selectedUser?.users_email,
          pos_number : selectedUser?.pos_number,
        }));
        if(res?.status === true && res?.result?.length > 0){
          setLoadingText('Calculating data, Please wait...');
          fetchUserDue();
          await setTransactionHistory(res.result);
          await calculateInvoiceData(res.result);
          
          setIsLoading(false);
        }else{
          setTotalSalesAmount(0);
          setTotalCancelAmount(0);
          setTotalPaidAmount(0);
          setTotalDueAmount(0);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const getUsersData = async (searchText='') =>{
    try {
      let searchString = searchText;
      let store_name, users_name, users_mobile, users_email, pos_number, bind_person_name;

      if(search_by === 'store_name'){
        store_name = { $regex: searchString, $options: "i" }
      } else if(search_by === 'users_name'){
        users_name = { $regex: searchString, $options: "i" };
      } else if(search_by === 'users_mobile'){
        users_mobile = parseInt(searchString);
      } else if(search_by === 'users_email'){
        users_email = { $regex: searchString, $options: "i" };
      } else if(search_by === 'pos_number'){
        pos_number = parseInt(searchString);
      } else if(search_by === 'bind_person_name'){
        bind_person_name = { $regex: searchString, $options: "i" };
      }

      const option = {
        condition : { 
          status : "A",
          ...(store_name?{store_name:store_name}:null),
          ...(users_name?{users_name:users_name}:null),
          ...(users_mobile?{users_mobile:users_mobile}:null),
          ...(users_email?{users_email:users_email}:null),
          ...(pos_number?{pos_number:pos_number}:null),
          ...(bind_person_name?{bind_person_name:bind_person_name}:null)
          // ...(search_by === ''?{ $text : { $search: searchString } }:null),
        },
        select : { users_type : true, users_name : true, last_name : true, users_mobile : true, users_email : true, users_mobile : true, store_name : true, area : true, bind_person_name : true, commission_percentage : true, users_id : true, pos_device_id : true, pos_number : true },
        sort : {store_name : 1}
        // limit : 50
      }
      const res = await userList(option);
      if(res.status === true){
        setAllUsersList(res?.result || []);
        // calculateTotalSales(res?.result);
      }
      setOptionsLoading(false);
    } catch (error) {
      
    }
  }

  useEffect(() => {
    document.title = `U-Winn CRM || ${ADDEDITDATA?._id ? "Edit" : "Add"
      } Invoice`;
  }, []);

  const debounceTimeout = useRef(null);

  const handleSearch = (value) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      if (value.trim() !== "" && value.length > 3) {
        getUsersData(value);
      }
    }, 500); 
  };

  useEffect(()=>{
    if(ALLUSERSLIST.length > 0){
      const transformedArray = ALLUSERSLIST.map(user => ({
        label: `${user.users_name} | ${user.store_name} | ${user.users_mobile}`,
        value: JSON.stringify(user) 
      }));
      setOptions(transformedArray);
    }
  },[ALLUSERSLIST]);

  const handleUserChange = (value) =>{
    try {
      setSelectedUser(JSON.parse(value));
    } catch (error) {
      
    }
  }
/*********** Calculate Total Sales Amount ******* */
  const calculateInvoiceData = (data) => {
    try {
      if (data.length === 0) {
        setLottoTotalSalesAmount(0);
        setLottoTotalCommissionAmount(0);
        setLottoTotalPaidAmount(0);

        setRaffleTotalSalesAmount(0);
        setRaffleTotalCommissionAmount(0);
        setRaffleTotalPaidAmount(0);

        setPointsTotalSalesAmount(0);
        setPointsTotalCommissionAmount(0);
        setPointsTotalPaidAmount(0);

        setTotalSalesAmount(0);
        setTotalCommissionAmount(0);
        setTotalPaidAmount(0);
        return;
      }

      //Lotto Summery
      const result = data.reduce(
        (acc, item) => {
          //Lotto
            if (item.narration === "Order") {
                acc.orderSum += item.upoints || 0;
            } else if (item.narration === "Order Cancalled") {
                acc.cancelledSum += item.upoints || 0;
            } else if(item.narration === 'Commission'){
                acc.commissionSum += parseFloat(item.upoints) || 0;
            } else if(item.narration === 'Commission Reverted'){
                acc.commissionRevertedSum += parseFloat(item.upoints) || 0;
            } else if(item.narration === 'Redeem Prize'){
              acc.paidSum += parseFloat(item.upoints) || 0;
            }
            //Raffle
            else if(item.narration === "Raffle Order"){
              acc.raffleOrdSum += item.upoints || 0;
            } else if(item.narration === "Raffle Order Cancalled"){
              acc.raffleCancelSum += item.upoints || 0;
            } else if(item.narration === "Raffle Commission"){
              acc.raffleCommissionSum += item.upoints || 0;
            } else if(item.narration === "Raffle Commission Reverted"){
              acc.raffleCommissionReverse += item.upoints || 0;
            } else if(item.narration === "Raffle Cash Prize Redeem"){
              acc.rafflePaidSum += item.upoints || 0;
            }
            //U-Points
            else if((item.narration === "Recharge" || item.narration === "Recharge Coupon") && item.record_type === 'Debit'){
              acc.pointsOrdSum += item.upoints || 0;
            } else if(item.narration === "Recharge Coupon Cancelled" || item.narration === "Reverse Recharge"){
              acc.pointsCancelSum += item.upoints || 0;
            } else if(item.narration === "Recharge Commission"){
              acc.pointsCommissionSum += item.upoints || 0;
            } else if(item.narration === "Referrel Commission"){
              acc.pointsInsensitive += item.upoints || 0;
            } else if(item.narration === "Recharge Commission Reverted"){
              acc.pointsCommissionReverse += item.upoints || 0;
            } else if(item.narration === "Cash Prize Redeem"){
              acc.pointsPaidSum += item.upoints || 0;
            }

            return acc;
        },
        { 
          orderSum: 0, cancelledSum: 0, commissionSum : 0, commissionRevertedSum:0, paidSum : 0,
          raffleOrdSum : 0, raffleCancelSum : 0, raffleCommissionSum : 0, raffleCommissionReverse : 0, rafflePaidSum : 0, 
          pointsOrdSum : 0, pointsCancelSum : 0, pointsCommissionSum : 0, pointsCommissionReverse : 0, pointsPaidSum : 0, pointsInsensitive : 0
        }
      );
      console.log('result Individual', result);
      const totalSales = parseFloat(result.orderSum) - parseFloat(result.cancelledSum);
      const totalCommission = parseFloat(result.commissionSum) - parseFloat(result.commissionRevertedSum);
      //Lotto
      setLottoTotalSalesAmount(totalSales);
      setLottoTotalCommissionAmount(totalCommission);
      setLottoTotalPaidAmount(result?.paidSum || 0);
      //Raffle
      const totalRaffleSales = parseFloat(result.raffleOrdSum) - parseFloat(result.raffleCancelSum);
      const totalRaffleCommission = parseFloat(result.raffleCommissionSum) - parseFloat(result.raffleCommissionReverse);
      setRaffleTotalSalesAmount(totalRaffleSales);
      setRaffleTotalCommissionAmount(totalRaffleCommission);
      setRaffleTotalPaidAmount(result?.rafflePaidSum || 0);
      //U-Points
      const totalPointsSales = parseFloat(result.pointsOrdSum) - parseFloat(result.pointsCancelSum);
      const totalPointsCommission = parseFloat(result.pointsCommissionSum) - parseFloat(result.pointsCommissionReverse);
      setPointsTotalSalesAmount(totalPointsSales);
      setPointsTotalCommissionAmount(totalPointsCommission);
      setPointsTotalInsensitiveAmount(result.pointsInsensitive || 0);
      setPointsTotalPaidAmount(result?.pointsPaidSum || 0);



    } catch (error) {
      console.log('error',error)

      setLottoTotalSalesAmount(0);
      setLottoTotalCommissionAmount(0);
      setLottoTotalPaidAmount(0);

      setRaffleTotalSalesAmount(0);
      setRaffleTotalCommissionAmount(0);
      setRaffleTotalPaidAmount(0);

      setPointsTotalSalesAmount(0);
      setPointsTotalCommissionAmount(0);
      setPointsTotalPaidAmount(0);

      setTotalSalesAmount(0);
      setTotalCommissionAmount(0);
      setTotalPaidAmount(0);

    }
  };

useEffect(()=>{

  const salesAmount = parseFloat(lottoTotalSalesAmount) || 0; 
  const commissionAmount = parseFloat(lottoTotalCommissionAmount) || 0; 
  const paidAmount = parseFloat(lottoTotalPaidAmount) || 0;

  setInvoiceData((pre)=>({
    ...pre,
    lottoTotalSales : salesAmount?.toFixed(2),
    lottoTotalCommission : commissionAmount?.toFixed(2),
    lottoPaidAmount : paidAmount?.toFixed(2),
  }))

},[lottoTotalSalesAmount, lottoTotalCommissionAmount, lottoTotalCancelAmount, lottoTotalPaidAmount]);

useEffect(()=>{
  const salesAmount = parseFloat(raffleTotalSalesAmount) || 0; 
  const commissionAmount = parseFloat(raffleTotalCommissionAmount) || 0; 
  const paidAmount = parseFloat(raffleTotalPaidAmount) || 0;

  setTotalDueAmount(raffleTotalSalesAmount);
  setSubTotalDueAmount(subTotalDueAmount);

  setInvoiceData((pre)=>({
    ...pre,
    raffleTotalSales : salesAmount?.toFixed(2),
    raffleTotalCommission : commissionAmount?.toFixed(2),
    rafflePaidAmount : paidAmount?.toFixed(2),
  }))

},[raffleTotalSalesAmount, raffleTotalCommissionAmount, raffleTotalCancelAmount, raffleTotalPaidAmount]);

useEffect(()=>{

  const salesAmount = parseFloat(pointsTotalSalesAmount) || 0; 
  const commissionAmount = parseFloat(pointsTotalCommissionAmount) || 0; 
  const paidAmount = parseFloat(pointsTotalPaidAmount) || 0;
  const insensitiveAmount = parseFloat(pointsTotalInsensitiveAmount || 0);

  setInvoiceData((pre)=>({
    ...pre,
    pointsTotalSales : salesAmount?.toFixed(2),
    pointsTotalCommission : commissionAmount?.toFixed(2),
    pointsPaidAmount : paidAmount?.toFixed(2),
    pointsIncentives : insensitiveAmount.toFixed(2)

  }))

},[pointsTotalSalesAmount, pointsTotalCommissionAmount, pointsTotalCancelAmount, pointsTotalPaidAmount, pointsTotalInsensitiveAmount]);

useEffect(()=>{
  let salesAmount = 0;
  let commissionAmount = 0;
  let paidAmount = 0;
  let insensitiveAmount = 0;

  let totalDueAmount = 0;
  let subTotalDue = 0;
  let total_debit = 0;

  if(parseFloat(invoiceData?.lottoTotalSales) > 0) salesAmount += parseFloat(invoiceData?.lottoTotalSales);
  if(parseFloat(invoiceData?.lottoTotalCommission) > 0) commissionAmount += parseFloat(invoiceData?.lottoTotalCommission);
  if(parseFloat(invoiceData?.lottoPaidAmount) > 0) paidAmount += parseFloat(invoiceData?.lottoPaidAmount);
  if(parseFloat(invoiceData?.lottoIncentives) > 0) insensitiveAmount += parseFloat(invoiceData?.lottoIncentives);


  if(parseFloat(invoiceData?.raffleTotalSales) > 0) salesAmount += parseFloat(invoiceData?.raffleTotalSales);
  if(parseFloat(invoiceData?.raffleTotalCommission) > 0) commissionAmount += parseFloat(invoiceData?.raffleTotalCommission);
  if(parseFloat(invoiceData?.rafflePaidAmount) > 0) paidAmount += parseFloat(invoiceData?.rafflePaidAmount);
  if(parseFloat(invoiceData?.raffleIncentives) > 0) insensitiveAmount += parseFloat(invoiceData?.raffleIncentives);

  if(parseFloat(invoiceData?.pointsTotalSales) > 0) salesAmount += parseFloat(invoiceData?.pointsTotalSales);
  if(parseFloat(invoiceData?.pointsTotalCommission) > 0) commissionAmount += parseFloat(invoiceData?.pointsTotalCommission);
  if(parseFloat(invoiceData?.pointsPaidAmount) > 0) paidAmount += parseFloat(invoiceData?.pointsPaidAmount);
  if(parseFloat(invoiceData?.pointsIncentives) > 0) insensitiveAmount += parseFloat(invoiceData?.pointsIncentives);
  
  total_debit = commissionAmount + paidAmount;
  
  totalDueAmount += parseFloat(salesAmount) 
  totalDueAmount -= parseFloat(total_debit) 
  totalDueAmount -= parseFloat(insensitiveAmount);
  
  
  subTotalDue += parseFloat(totalDueAmount);

  const old_balance = invoiceData?.old_balance || 0;
  const other_charges = invoiceData?.other_charges || 0;
  subTotalDue += parseFloat(old_balance);
  subTotalDue += parseFloat(other_charges);

  setInvoiceData((pre)=>({
    ...pre,
    totalSales : salesAmount?.toFixed(2),
    totalCommission : commissionAmount?.toFixed(2),
    paidAmount : paidAmount?.toFixed(2),
    incentives : parseFloat(insensitiveAmount)?.toFixed(2) || 0,

    totalDue : totalDueAmount,
    subTotalDue : subTotalDue
  }))

},[
  invoiceData?.lottoTotalSales, invoiceData?.lottoTotalCommission, invoiceData?.lottoPaidAmount, 
  invoiceData?.raffleTotalSales, invoiceData?.raffleTotalCommission, invoiceData?.raffleIncentives, 
  invoiceData?.pointsTotalSales,invoiceData?.pointsTotalCommission,invoiceData?.pointsIncentives,
  invoiceData?.old_balance, invoiceData?.other_charges 
]);

const handlePreview = (data = {})=>{
  try {
      const params = {
        invoice_no          : invoiceData?.invoice_no || data.invoice_no,
        invoice_date        : invoiceData?.invoice_date,
        
        users_id            : selectedUser?.users_id,
        users_oid           : selectedUser?._id,
        store               : selectedUser?.store_name,
        users_name          : `${selectedUser?.users_name} ${selectedUser?.last_name}`,
        users_mobile        : selectedUser?.users_mobile,
        sales_person_name   : selectedUser?.bind_person_name,
        area                : selectedUser?.area,
        pos_device_id       : selectedUser?.pos_device_id,
        pos_number          : selectedUser?.pos_number,
        payment_mode        : invoiceData?.payment_mode,
  
        start_date          : `${ADDEDITDATA?.start_date} ${ADDEDITDATA?.start_time}`,
        end_date            : `${ADDEDITDATA?.end_date} ${ADDEDITDATA?.end_time}`,
        due_date            : invoiceData?.due_date?new Date(invoiceData.due_date):'',

        lottoTotalSales     : invoiceData?.lottoTotalSales || 0,
        lottoTotalCommission : invoiceData?.lottoTotalCommission || 0,
        lottoPaidAmount     : invoiceData?.lottoPaidAmount || 0,
        lottoIncentives     : invoiceData?.lottoIncentives || 0,
        
        raffleTotalSales    : invoiceData?.raffleTotalSales || 0,
        raffleTotalCommission : invoiceData?.raffleTotalCommission || 0,
        rafflePaidAmount    : invoiceData?.rafflePaidAmount || 0,
        raffleIncentives     : invoiceData?.raffleIncentives || 0,

        pointsTotalSales    : invoiceData?.pointsTotalSales || 0,
        pointsTotalCommission : invoiceData?.pointsTotalCommission || 0,
        pointsPaidAmount    : invoiceData?.pointsPaidAmount || 0,
        pointsIncentives     : invoiceData?.pointsIncentives || 0,
  
        total_sales         : invoiceData?.totalSales,
        total_commission    : invoiceData?.totalCommission,
        total_paid          : invoiceData?.paidAmount?invoiceData.paidAmount:0,
        total_incentives    : invoiceData?.incentives,
        total_due           : invoiceData?.totalDue,
  
        old_pending_balance : invoiceData?.old_balance || 0,
        other_charges       : invoiceData?.other_charges || 0,
        sub_total_due       : invoiceData?.subTotalDue,
  
      }
      
      const {
        store,users_name, pos_number, users_mobile, invoice_date, invoice_no, payment_mode, start_date, end_date, sales_person_name, area,

        lottoTotalSales, lottoTotalCommission, lottoPaidAmount, lottoIncentives,
        raffleTotalSales, raffleTotalCommission, rafflePaidAmount, raffleIncentives,
        pointsTotalSales, pointsTotalCommission, pointsPaidAmount, pointsIncentives,
 
        sub_total_due, total_incentives, 

        total_sales, total_commission, total_paid, total_due, 
        
        other_charges, old_pending_balance, 
       } = params;
     
      let previewURL = `/invoice/preview?`
      previewURL    +=  `autoDownload=N`
      previewURL    +=  `&store_name=${store}`
      previewURL    +=  `&user_name=${users_name}`
      previewURL    +=  `&pos_number=${pos_number}`
      previewURL    +=  `&mobile=${users_mobile}`
      previewURL    +=  `&totalSales=${total_sales}`
      previewURL    +=  `&totalCommission=${total_commission}`
      previewURL    +=  `&paidAmount=${total_paid}`
      previewURL    +=  `&totalDue=${total_due}`
      previewURL    +=  `&other_charges=${other_charges}`
      previewURL    +=  `&old_balance=${old_pending_balance}`
      previewURL    +=  `&subTotalDue=${sub_total_due}`
      previewURL    +=  `&incentives=${total_incentives}`
      previewURL    +=  `&invoice_date=${invoice_date}`
      previewURL    +=  `&invoice_no=${invoice_no}`
      previewURL    +=  `&payment_mode=${payment_mode}`
      previewURL    +=  `&start_date=${start_date}`
      previewURL    +=  `&end_date=${end_date}`
      previewURL    +=  `&sales_person_name=${sales_person_name}`
      previewURL    +=  `&area=${area}`

      previewURL    +=  `&lottoTotalSales=${lottoTotalSales}`
      previewURL    +=  `&lottoTotalCommission=${lottoTotalCommission}`
      previewURL    +=  `&lottoPaidAmount=${lottoPaidAmount}`
      previewURL    +=  `&lottoIncentives=${lottoIncentives}`

      previewURL    +=  `&raffleTotalSales=${raffleTotalSales}`
      previewURL    +=  `&raffleTotalCommission=${raffleTotalCommission}`
      previewURL    +=  `&rafflePaidAmount=${rafflePaidAmount}`
      previewURL    +=  `&raffleIncentives=${raffleIncentives}`

      previewURL    +=  `&pointsTotalSales=${pointsTotalSales}`
      previewURL    +=  `&pointsTotalCommission=${pointsTotalCommission}`
      previewURL    +=  `&pointsPaidAmount=${pointsPaidAmount}`
      previewURL    +=  `&pointsIncentives=${pointsIncentives}`

      
      window.open(previewURL, '_blank');
  } catch (error) {
    alert('Invoice data not fount');
  }
}

const handleGenerate = async () => {
  try {
    setLoadingText('Please wait your request is in process.')
    setIsLoading(true);
    const ipAddress  = await fetchIpAddress();
    const params = {
      invoice_no          : invoiceData?.invoice_no,
      invoice_date        : invoiceData?.invoice_date,
      
      users_id            : selectedUser?.users_id,
      users_oid           : selectedUser?._id,
      store               : selectedUser?.store_name,
      users_name          : `${selectedUser?.users_name} ${selectedUser?.last_name}`,
      users_mobile        : selectedUser?.users_mobile,
      area                : selectedUser?.area?selectedUser.area:'N/A',
      sales_person_name   : selectedUser?.bind_person_name?selectedUser.bind_person_name:'N/A',      pos_device_id       : selectedUser?.pos_device_id,
      pos_number          : selectedUser?.pos_number,
      payment_mode        : invoiceData?.payment_mode,

      // start_date          : new Date(`${ADDEDITDATA?.start_date} ${ADDEDITDATA?.start_time}`),
      // end_date            : new Date(`${ADDEDITDATA?.end_date} ${ADDEDITDATA?.end_time}`),
      start_date          : `${ADDEDITDATA?.start_date} ${ADDEDITDATA?.start_time}`,
      start_time          : ADDEDITDATA?.start_time,
      end_date            : `${ADDEDITDATA?.end_date} ${ADDEDITDATA?.end_time}`,
      end_time            : ADDEDITDATA?.end_time,
      due_date            : invoiceData?.due_date || '',


      
      lottoTotalSales     : invoiceData?.lottoTotalSales || 0,
      lottoTotalCommission : invoiceData?.lottoTotalCommission || 0,
      lottoPaidAmount     : invoiceData?.lottoPaidAmount || 0,
      lottoIncentives     : invoiceData?.lottoIncentives || 0,
      
      raffleTotalSales    : invoiceData?.raffleTotalSales || 0,
      raffleTotalCommission : invoiceData?.raffleTotalCommission || 0,
      rafflePaidAmount    : invoiceData?.rafflePaidAmount || 0,
      raffleIncentives     : invoiceData?.raffleIncentives || 0,

      pointsTotalSales    : invoiceData?.pointsTotalSales || 0,
      pointsTotalCommission : invoiceData?.pointsTotalCommission || 0,
      pointsPaidAmount    : invoiceData?.pointsPaidAmount || 0,
      pointsIncentives     : invoiceData?.pointsIncentives || 0,


      total_sales         : invoiceData?.totalSales,
      total_commission    : invoiceData?.totalCommission,
      total_paid          : invoiceData?.paidAmount?invoiceData.paidAmount:0,
      total_incentives    : invoiceData?.incentives,
      total_due           : invoiceData?.totalDue,

      old_pending_balance : invoiceData?.old_balance,
      other_charges       : invoiceData?.other_charges,
      sub_total_due       : invoiceData?.subTotalDue,

      created_ip          : ipAddress
    }
    const res = await createInvoice(params);
    if(res.status === true){
      notification.open({
        message: "Success.",
        description: `Invoice generated Successfully.`,
        placement: "topRight",
        icon: <CheckCircleOutlined style={{ color: "green" }} />,
        duration: 2,
      });
      handlePreview(res.result);
      navigate('/invoice/list');
    } else{
      notification.open({
        message: "Oops!.",
        description: `${res.message}`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
  }
}
  
  return (
    <div>
      {" "}
      <div className="container-fluid">
        <div className="Inventory_edit_page_main_div">
          <React.Fragment>
            <h5 className="inventory_edit_page_heading"> Generate Invoice </h5>
          </React.Fragment>
          <Link to="/sub-admin/list">
            <React.Fragment>
              <button type="button" className="btn btn-secondary"> <ArrowLeftOutlined /> Back </button>
            </React.Fragment>
          </Link>
        </div>

        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-body">
            <div className="responsive">
              <form method="post" className="inventory_form_main_contnet" onSubmit={handleSubmit}>
                <div className="row ">
                  <div className="row">
                    {/* Search By */}
                    <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="admin_first_name" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Customer/Retailer's store :{" "}
                      </label>
                      <select title="Choose your option" name="search_by" id="search_by" className="form-control store_input_field" onChange={handleSearchBy}>
                        <option value="" selected disabled>Choose your option</option>
                        <option value="store_name">Store name</option>
                        <option value="users_name">users name</option>
                        <option value="users_mobile">users mobile</option>
                        <option value="users_email">users email</option>
                        <option value="pos_number">POS No.</option>
                        <option value="bind_person_name">Bind person name</option>
                      </select>
                    </div>

                    <div className="form-group col-lg-8 col-md-8 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="admin_first_name"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>Customer/Retailer :{" "}
                      </label>
                      <Select
                       className="form-control"
                       onSearch={handleSearch}
                       onChange={handleUserChange}
                       showSearch={true}
                       defaultOpen={true}
                       autoClearSearchValue={false}
                       placeholder="Search to Select"
                       notFoundContent={null}
                       loading={optionsLoading}
                       options={OPTIONS}
                      />
                      {error.admin_first_name ? (
                        <p style={{ color: "red" }}>{error.admin_first_name}</p>
                      ) : (
                        ""
                      )}
                    </div>


                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="start_date"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>From :{" "}
                      </label>
                      <input
                        type="date"
                        name="start_date"
                        className="form-control store_input_field"
                        id="start_date"
                        onChange={handleChange}
                      />
                      {error.start_date ? (
                        <p style={{ color: "red" }}>{error.start_date}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="start_time"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>{" "}
                      </label>
                      <input
                        type="time"
                        name="start_time"
                        className="form-control store_input_field"
                        id="start_time"
                        value={ADDEDITDATA?.start_time}
                        onChange={handleChange}
                      />
                      {error.start_time ? (
                        <p style={{ color: "red" }}>{error.start_time}</p>
                      ) : (
                        ""
                      )}
                    </div>


                    <div className="form-group col-lg-3 col-md-3 col-sm-16 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="end_date"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>To :
                      </label> 
                      <input
                        type="date"
                        name="end_date"
                        className="form-control store_input_field"
                        id="end_date"
                        onChange={handleChange}
                      />   
                      {error.end_date ? (
                        <p style={{ color: "red" }}>{error.end_date}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>{" "}
                      </label>
                      <input
                        type="time"
                        name="end_time"
                        className="form-control store_input_field"
                        id="end_time"
                        value={ADDEDITDATA?.end_time}
                        onChange={handleChange}
                      />
                      {error.end_time ? (
                        <p style={{ color: "red" }}>{error.end_time}</p>
                      ) : (
                        ""
                      )}
                    </div>

                  </div>
                </div>

                <div className="inventory_edit_submit_btn">
                  <button type="submit" className="btn btn-primary inventory_save_button" > Auto Calculate </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      {/* Generate Invoice Section */}
        <div className="card shadow mb-4 dataBase_table_Add_Data">
          <div className="card-body">
            <div className="responsive">
              {/* <form method="post" className="inventory_form_main_contnet"> */}
                <div className="row ">
                  <div className="row">
                    {/* Store Name */}
                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="admin_first_name" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Customer/Retailer's store :{" "}
                      </label>
                      <input
                        title="Customer/Retailer's Store."
                        placeholder="Customer/Retailer's store"
                        type="text"
                        name="store_name"
                        className="form-control store_input_field"
                        id="store_name"
                        value={invoiceData?.store_name || ''}
                        onChange={invoiceDataChange}
                      />
                      {error.store_name ? ( <p style={{ color: "red" }}>{error.store_name}</p> ) : ( "" )}
                    </div>
                    
                    {/* User's Name */}
                    <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="user_name" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Name :{" "}
                      </label>
                      <input
                        title="User's Name."
                        placeholder="Customer/Retailer's name"
                        type="text"
                        name="user_name"
                        className="form-control store_input_field"
                        id="user_name"
                        value={invoiceData?.user_name || ''}
                        onChange={invoiceDataChange}
                      />
                      {error.user_name ? ( <p style={{ color: "red" }}>{error.user_name}</p> ) : ( "" )}
                    </div>
                    {/* User's Mobile */}
                    <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="mobile" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Mobile no. :{" "}
                      </label>
                      <input
                        title="Mobile No."
                        placeholder="Mobile No."
                        type="text"
                        name="mobile"
                        className="form-control store_input_field"
                        id="mobile"
                        value={invoiceData?.mobile}
                        onChange={invoiceDataChange}
                      />
                      {error.mobile ? ( <p style={{ color: "red" }}>{error.mobile}</p> ) : ( "" )}
                    </div>
                    {/* Start Date */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="start_date"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>From :{" "}
                      </label>
                      <input
                        type="date"
                        name="start_date"
                        className="form-control store_input_field"
                        id="start_date"
                        value={ADDEDITDATA?.start_date}
                        onChange={handleChange}
                      />
                      {error.start_date ? (
                        <p style={{ color: "red" }}>{error.start_date}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* Start Time */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="start_time"
                        className="all_section_for_label_main_class"
                      >
                        <span style={{ color: "red" }}>*</span>{" "}
                      </label>
                      <input
                        type="time"
                        name="start_time"
                        className="form-control store_input_field"
                        id="start_time"
                        value={ADDEDITDATA?.start_time}
                        onChange={handleChange}
                      />
                      {error.start_time ? (
                        <p style={{ color: "red" }}>{error.start_time}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* End Date */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-16 col-xs-12 vendor_Add_edit_data">
                      <label
                        htmlFor="end_date"
                        className="all_section_for_label_main_class all_lebel_margin_top_main_div"
                      >
                        <span style={{ color: "red" }}>*</span>To :
                      </label> 
                      <input
                        type="date"
                        name="end_date"
                        className="form-control store_input_field"
                        id="end_date"
                        value={ADDEDITDATA?.end_date}
                        onChange={handleChange}
                      />   
                      {error.end_date ? (
                        <p style={{ color: "red" }}>{error.end_date}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* End Time */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>{" "}
                      </label>
                      <input
                        type="time"
                        name="end_time"
                        className="form-control store_input_field"
                        id="end_time"
                        value={ADDEDITDATA?.end_time}
                        onChange={handleChange}
                      />
                      {error.end_time ? (
                        <p style={{ color: "red" }}>{error.end_time}</p>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* Machine Ref */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="pos_number" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Machine Ref. :{" "}
                      </label>
                      <input
                        title="Machine Ref."
                        placeholder="Machine Ref"
                        type="text"
                        name="pos_number"
                        className="form-control store_input_field"
                        id="pos_number"
                        value={invoiceData?.pos_number}
                        onChange={invoiceDataChange}
                      />
                      {error.pos_number ? ( <p style={{ color: "red" }}>{error.pos_number}</p> ) : ( "" )}
                    </div>
                    {/* Invoice No. */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="invoice_no" className="all_section_for_label_main_class" >Invoice no. :</label>
                      <input
                        title="Invoice No."
                        placeholder="Invoice No."
                        type="text"
                        name="invoice_no"
                        className="form-control store_input_field"
                        id="invoice_no"
                        value={invoiceData?.invoice_no}
                        onChange={invoiceDataChange}
                      />
                      {error.invoice_no ? ( <p style={{ color: "red" }}>{error.invoice_no}</p> ) : ( "" )}
                    </div>
                    {/* Invoice Date */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="invoice_date" className="all_section_for_label_main_class" ><span style={{ color: "red" }}>*</span>Invoice date :</label>
                      <input
                        title="Invoice Date"
                        placeholder="Invoice Date"
                        type="date"
                        name="invoice_date"
                        className="form-control store_input_field"
                        id="invoice_date"
                        value={invoiceData?.invoice_date || ''}
                        onChange={invoiceDataChange}
                      />
                      {error.invoice_date ? ( <p style={{ color: "red" }}>{error.invoice_date}</p> ) : ( "" )}
                    </div>
                    {/* payment_mode */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="payment_mode" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Mode/Terms of payment :{" "}
                      </label>
                      <select name="payment_mode" id="payment_mode" className="form-control store_input_field" onChange={invoiceDataChange} >
                        <option value='' selected disabled >Choose your options</option>
                        <option value='Cash' >Cash</option>
                        <option value='Online' >Online</option>
                      </select>
                      {error.payment_mode ? ( <p style={{ color: "red" }}>{error.payment_mode}</p> ) : ( "" )}
                    </div>

                    {/* Lotto Total Sales */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="lottoTotalSales" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Lotto Total Sale :{" "}
                      </label>
                      <input
                        title="Lotto Total Sale"
                        placeholder="Lotto Total Sale"
                        type="number"
                        name="lottoTotalSales"
                        className="form-control store_input_field"
                        id="lottoTotalSales"
                        value={invoiceData?.lottoTotalSales || ''}
                        onChange={invoiceDataChange}
                      />
                      {error.lottoTotalSales ? ( <p style={{ color: "red" }}>{error.lottoTotalSales}</p> ) : ( "" )}
                    </div>

                    {/* Lotto Total Commission */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="lottoTotalCommission" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Lotto Total Commission :{" "}
                      </label>
                      <input
                        title="Lotto Total Commission"
                        placeholder="Lotto Total Commission"
                        type="number"
                        name="lottoTotalCommission"
                        className="form-control store_input_field"
                        id="lottoTotalCommission"
                        value={invoiceData?.lottoTotalCommission || ''}
                        onChange={invoiceDataChange}
                      />
                      {error.lottoTotalCommission ? ( <p style={{ color: "red" }}>{error.lottoTotalCommission}</p> ) : ( "" )}
                    </div>

                    {/* Lotto Amount Paid */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="lottoPaidAmount" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Lotto Amount Paid :{" "}
                      </label>
                      <input
                        title="Lotto Amount Paid"
                        placeholder="Lotto Amount Paid"
                        type="number"
                        name="lottoPaidAmount"
                        className="form-control store_input_field"
                        id="lottoPaidAmount"
                        value={invoiceData?.lottoPaidAmount || ''}
                        onChange={invoiceDataChange}
                      />
                      {error.lottoPaidAmount ? ( <p style={{ color: "red" }}>{error.lottoPaidAmount}</p> ) : ( "" )}
                    </div>

                    {/* Lotto Total Sales */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="lottoIncentives" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Lotto Other Incentives :{" "}
                      </label>
                      <input
                        title="Lotto Other Incentives"
                        placeholder="Lotto Other Incentives"
                        type="number"
                        name="lottoIncentives"
                        className="form-control store_input_field"
                        id="lottoIncentives"
                        value={invoiceData?.lottoIncentives || ''}
                        onChange={invoiceDataChange}
                      />
                      {error.lottoIncentives ? ( <p style={{ color: "red" }}>{error.lottoIncentives}</p> ) : ( "" )}
                    </div>

                    {/* Raffle Total Sales */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="raffleTotalSales" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Raffle Total Sales :{" "}
                      </label>
                      <input
                        title="Raffle Total Sale"
                        placeholder="Raffle Total Sale"
                        type="number"
                        name="raffleTotalSales"
                        className="form-control store_input_field"
                        id="raffleTotalSales"
                        value={invoiceData?.raffleTotalSales || ''}
                        onChange={invoiceDataChange}
                      />
                      {error.raffleTotalSales ? ( <p style={{ color: "red" }}>{error.raffleTotalSales}</p> ) : ( "" )}
                    </div>

                    {/* Raffle Total Commission */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="raffleTotalCommission" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Raffle Total Commission :{" "}
                      </label>
                      <input
                        title="Raffle Total Commission"
                        placeholder="Raffle Total Commission"
                        type="number"
                        name="raffleTotalCommission"
                        className="form-control store_input_field"
                        id="raffleTotalCommission"
                        value={invoiceData?.raffleTotalCommission || ''}
                        onChange={invoiceDataChange}
                      />
                      {error.raffleTotalCommission ? ( <p style={{ color: "red" }}>{error.raffleTotalCommission}</p> ) : ( "" )}
                    </div>

                    {/* Raffle Amount Paid */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="rafflePaidAmount" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Raffle Amount Paid :{" "}
                      </label>
                      <input
                        title="Raffle Amount Paid"
                        placeholder="Raffle Amount Paid"
                        type="number"
                        name="rafflePaidAmount"
                        className="form-control store_input_field"
                        id="rafflePaidAmount"
                        value={invoiceData?.rafflePaidAmount || ''}
                        onChange={invoiceDataChange}
                      />
                      {error.rafflePaidAmount ? ( <p style={{ color: "red" }}>{error.rafflePaidAmount}</p> ) : ( "" )}
                    </div>

                    {/* Raffle Other Incentives */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="raffleIncentives" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Raffle Other Incentives :{" "}
                      </label>
                      <input
                        title="Raffle Other Incentives"
                        placeholder="Raffle Other Incentives"
                        type="number"
                        name="raffleIncentives"
                        className="form-control store_input_field"
                        id="raffleIncentives"
                        value={invoiceData?.raffleIncentives || ''}
                        onChange={invoiceDataChange}
                      />
                      {error.raffleIncentives ? ( <p style={{ color: "red" }}>{error.raffleIncentives}</p> ) : ( "" )}
                    </div>

                    {/* U-Points Total Sales */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="pointsTotalSales" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Points Total Sales :{" "}
                      </label>
                      <input
                        title="Points Total Sales"
                        placeholder="Points Total Sales"
                        type="number"
                        name="pointsTotalSales"
                        className="form-control store_input_field"
                        id="pointsTotalSales"
                        value={invoiceData?.pointsTotalSales || ''}
                        onChange={invoiceDataChange}
                      />
                      {error.pointsTotalSales ? ( <p style={{ color: "red" }}>{error.pointsTotalSales}</p> ) : ( "" )}
                    </div>

                    {/* Points Total Commission */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="pointsTotalCommission" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Points Total Commission :{" "}
                      </label>
                      <input
                        title="Points Total Commission"
                        placeholder="Points Total Commission"
                        type="number"
                        name="pointsTotalCommission"
                        className="form-control store_input_field"
                        id="pointsTotalCommission"
                        value={invoiceData?.pointsTotalCommission || ''}
                        onChange={invoiceDataChange}
                      />
                      {error.pointsTotalCommission ? ( <p style={{ color: "red" }}>{error.pointsTotalCommission}</p> ) : ( "" )}
                    </div>

                    {/* Points Amount Paid */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="pointsPaidAmount" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Points Amount Paid :{" "}
                      </label>
                      <input
                        title="Points Amount Paid"
                        placeholder="Points Amount Paid"
                        type="number"
                        name="pointsPaidAmount"
                        className="form-control store_input_field"
                        id="pointsPaidAmount"
                        value={invoiceData?.pointsPaidAmount || ''}
                        onChange={invoiceDataChange}
                      />
                      {error.pointsPaidAmount ? ( <p style={{ color: "red" }}>{error.pointsPaidAmount}</p> ) : ( "" )}
                    </div>

                    {/* Points Other Incentives */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="pointsIncentives" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Points Other Incentives :{" "}
                      </label>
                      <input
                        title="Points Other Incentives"
                        placeholder="Points Other Incentives"
                        type="number"
                        name="pointsIncentives"
                        className="form-control store_input_field"
                        id="pointsIncentives"
                        value={invoiceData?.pointsIncentives || ''}
                        onChange={invoiceDataChange}
                      />
                      {error.pointsIncentives ? ( <p style={{ color: "red" }}>{error.pointsIncentives}</p> ) : ( "" )}
                    </div>

                    <hr className="mb-0"/>

                    {/* Total Sales */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data ">
                      <label htmlFor="totalSales" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Total sale :{" "}
                      </label>
                      <input
                        title="Total Sale"
                        placeholder="Total sale"
                        type="number"
                        name="totalSales"
                        className="form-control store_input_field"
                        id="totalSales"
                        disabled
                        value={invoiceData?.totalSales || ''}
                        // onChange={invoiceDataChange}
                      />
                      {error.totalSales ? ( <p style={{ color: "red" }}>{error.totalSales}</p> ) : ( "" )}
                    </div>
                    {/* Total Commission */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="totalCommission" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Total commission :{" "}
                      </label>
                      <input
                        title="Total Commission"
                        placeholder="Total commission"
                        type="number"
                        name="totalCommission"
                        className="form-control store_input_field"
                        id="totalCommission"
                        disabled
                        value={invoiceData?.totalCommission || ''}
                        // onChange={invoiceDataChange}
                      />
                      {error.totalCommission ? ( <p style={{ color: "red" }}>{error.totalCommission}</p> ) : ( "" )}
                    </div>
                    {/* Amount Paid */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="paidAmount" className="all_section_for_label_main_class" >
                        <span style={{ color: "red" }}>*</span>Amount paid:{" "}
                      </label>
                      <input
                        title="Amount Paid"
                        placeholder="Amount paid"
                        type="number"
                        name="paidAmount"
                        className="form-control store_input_field"
                        id="paidAmount"
                        disabled
                        value={invoiceData?.paidAmount || ''}
                        // onChange={invoiceDataChange}
                      />
                      {error.paidAmount ? ( <p style={{ color: "red" }}>{error.paidAmount}</p> ) : ( "" )}
                    </div>
                    {/* Other Incentives */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="incentives" className="all_section_for_label_main_class" > Other incentives :{" "} </label>
                      <input
                        title="Total incentives"
                        placeholder="Total incentives"
                        type="number"
                        name="incentives"
                        className="form-control store_input_field"
                        id="incentives"
                        disabled
                        value={invoiceData?.incentives || ''}
                        // onChange={invoiceDataChange}
                      />
                      {error.incentives ? ( <p style={{ color: "red" }}>{error.incentives}</p> ) : ( "" )}
                    </div>
               
                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data mt-5">
                      <h4 style={{textAlign : "left"}} className="amount_heading">Due amount : AED {invoiceData?.totalDue?.toFixed(2) || 0}</h4>
                    </div>
                    <hr className="mb-0"/>

                    {/* Old Balance */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="old_balance" className="all_section_for_label_main_class amount_heading" > Old balance pending :{" "}</label>
                      <input
                        title="Old balance pending"
                        placeholder="Old balance pending"
                        type="number"
                        name="old_balance"
                        className="form-control store_input_field"
                        id="old_balance"
                        value={invoiceData?.old_balance || ''}
                        onChange={invoiceDataChange}
                      />
                      {error.old_balance ? ( <p style={{ color: "red" }}>{error.old_balance}</p> ) : ( "" )}
                    </div>
                    {/* Other Charges */}
                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 vendor_Add_edit_data">
                      <label htmlFor="other_charges" className="all_section_for_label_main_class amount_heading" > Other charges (if any) :{" "} </label>
                      <input
                        title="Other charges"
                        placeholder="Other charges"
                        type="number"
                        name="other_charges"
                        className="form-control store_input_field"
                        id="other_charges"
                        value={invoiceData?.other_charges || ''}
                        onChange={invoiceDataChange}
                      />
                      {error.other_charges ? ( <p style={{ color: "red" }}>{error.other_charges}</p> ) : ( "" )}
                    </div>

                  
                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 vendor_Add_edit_data mt-4 mb-2">
                      <h4 style={{textAlign : "left"}}>Total due as per statement : AED {invoiceData?.subTotalDue?.toFixed(2) || 0}</h4>
                    </div>
                    <hr/>

                  </div>

                </div>

                <div className="inventory_edit_submit_btn mt-3">
                  <button type="button" className="btn btn-danger inventory_save_button mx-2" onClick={handlePreview} > Preview </button>
                  <button type="button" className="btn btn-primary inventory_save_button" onClick={handleGenerate} > Generate </button>
                </div>
              {/* </form> */}
            </div>
          </div>
        </div>
      </div>
      <LoadingEffect isLoading={isLoading} text={loadingText} />
    </div>
  );
};

export default UserListEditPage;
