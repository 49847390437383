import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Typography} from "antd";
import { notification } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faTimesCircle  } from '@fortawesome/free-regular-svg-icons';  
import { InfoCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

import { addeditdata, updateDueAmount } from "../../../controllers/V1/customerDue/dueController";

import ExcelJS from 'exceljs';
import { Link } from "react-router-dom";
const VendorListDeletePopUp = ({ popUpOpen, togglePopUp }) => {
  const handleCancel = () => {
    togglePopUp();
    setJsonData({});
    setDueCount(0);
    setProgressBar(false);
    setStartImport(false);
  };
  const [ errors, setError] = useState({});
 
  const [jsonData, setJsonData] = useState([]);
  const [progressBar, setProgressBar] = useState(false);
  const [dueCount, setDueCount] = useState(0);
  const [startImport, setStartImport] = useState(false);
  const toggleProgressBar = ()=>setProgressBar(!progressBar);

  const handleChangeFile = (e) => {
    try {
      // console.log('file : ', e.target.files[0]);
      const file = e.target.files[0];
      const workbook = new ExcelJS.Workbook();
      const reader = new FileReader();

      reader.onload = async (event) => {
        const buffer = event.target.result;
        await workbook.xlsx.load(buffer);
  
        const worksheet = workbook.worksheets[0];
        const rows = [];
  
        worksheet.eachRow((row, rowNumber) => {
          const rowValues = row.values.slice(1); // Remove the first empty value
          rows.push(rowValues);
        });
  
        // Convert rows to JSON (assuming first row is the header)
        const headers = rows[0];
        const data = rows.slice(1).map(row => {
          const rowData = {};
          row.forEach((cell, index) => {
            rowData[headers[index]] = cell;
          });
          return rowData;
        });
        setJsonData(data);
        // console.log('data',data)
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.log('error',error);
    }
  }

  const generateDue = () =>{
    try {
      if(jsonData.length > 0){
        toggleProgressBar();
        setStartImport(true);
        setDueCount(jsonData.length);
        // notification.open({
        //   message: "Success.",
        //   description: `Invoice generated Successfully.`,
        //   placement: "topRight",
        //   icon: <CheckCircleOutlined style={{ color: "green" }} />,
        //   duration: 2,
        // });
      } else{
        notification.open({
          message: "Oops!.",
          description: `No data found.`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      setDueCount(false);
      notification.open({
        message: "Oops!.",
        description: `No data found.`,
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
  }

  useEffect(()=>{
    if(dueCount > 0){
      handleUpdate(jsonData[dueCount - 1])
    }
  },[dueCount]);


  const handleUpdate = async (edit_data) => {
    try {
      if(!edit_data.amount){
        setDueCount(dueCount - 1);
        setJsonData((prev) =>
          prev.map((item) =>
            item.pos_number === edit_data.pos_number ? { 
              ...item, 
              status: 'Completed', 
              remarks : 'Due amount is required.'
            } : item
          )
        );
      } else if(!edit_data.pos_number){
        setDueCount(dueCount - 1);
        setJsonData((prev) =>
          prev.map((item) =>
            item.pos_number === edit_data.pos_number ? { 
              ...item, 
              status: 'Completed', 
              remarks : 'POS number is required.'
            } : item
          )
        );
      } else {
        const option = {
          pos_number : edit_data?.pos_number,
          due_amount : edit_data?.amount
        }
        const res = await updateDueAmount(option);
        if(res.status === true){
          setDueCount(dueCount - 1);
          setJsonData((prev) =>
            prev.map((item) =>
              item.pos_number === edit_data.pos_number ? { 
                ...item, 
                status: 'Completed', 
                remarks : '-'
              } : item
            )
          );
        }else {
          setDueCount(dueCount - 1);
          setJsonData((prev) =>
            prev.map((item) =>
              item.pos_number === edit_data.pos_number ? { 
                ...item, 
                status: 'Fail', 
                remarks : `${res?.message}`
              } : item
            )
          );
        }
      }
    } catch (error) {
      setDueCount(dueCount - 1);
    }
  }
  return (
    <Dialog
      open={popUpOpen}
      onClose={handleCancel}
      fullWidth={true}
      maxWidth={"md"}
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        
        <div className="myDrawer">
          <div className="inventory_popup_title">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="inventory_popup_title_heading"
            >
              Import customer's due
            </Typography>
            <Link to={false} style={{ position: "absolute", right: 8, top: 8, color: "#dd5151" }} onClick={handleCancel}><FontAwesomeIcon icon={faTimesCircle}/></Link>
          </div>
          <Typography
            variant="body1"
            component="div"
            className="my_delete_popup_paragraph"
          >
            <div className="container-fluid">
              <div className="categories_open_popup_main_contnet">
                <div className="form-group row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <label for="due_list" className="all_lable_for_vendor_dashbaord" >
                    <span style={{color:"red"}}>*</span>Choose an file :
                    </label>
                    <input type="file" name="due_list" id="due_list" onChange={handleChangeFile} class="form-control categories_select_tg_enter_product" />
                    {errors?.search_by?(<span className="text-danger">{errors.search_by}</span>):""}
                  </div>

                  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                    <Link to="/templates/import template.xlsx" target="__blank">
                      <label for="title" className="all_lable_for_vendor_dashbaord" > Download template </label>
                    </Link>
                  </div>

                  {(progressBar && jsonData?.length > 0) && (
                    <div className="inventory_popup_title">
                      <h5>
                        Pending {dueCount} out of {jsonData?.length}
                      </h5>
                    </div>                          
                    )}

                  <div 
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12" 
                    style={{
                      maxHeight: "220px",
                      overflowY: "auto",
                      border: "1px solid #ddd",
                    }}>
                    <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0" >
                      <thead>
                        <tr>
                          <th>Sl. No.</th>
                          <th>POS Number</th>
                          <th>Due Amount</th>
                          <th>Status</th>
                          <th>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jsonData?.length > 0 ? (
                          jsonData.map((items, index) => (
                            <>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{items?.pos_number}</td>
                                <td>{items?.amount || items?.due_amount}</td>
                                <td>{items?.status?items.status:"Pending"}</td>
                                <td>{items?.remarks}</td>
                              </tr>
                            </>))
                          ):(
                            <tr> 
                              <td colspan="6" className="img-center" >No data found !</td> 
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="inventory_open_popup_action_btn">
                      <button type="button" className="btn btn-light categories_cancel_button" onClick={handleCancel} > Cancel </button>
                      <button type="button" className="btn btn-danger" onClick={generateDue} disabled={startImport === true?true:false}> Import Start </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Typography>
        </div>
      </div>
    </Dialog>
  );
};

export default VendorListDeletePopUp;
