import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import SidebarLogo from "../Image/SideBarIcon.png";
import SidebarLogo from "../../Image/SideBarIcon.png";
import { Collapse } from "antd";
import "./SideBar.css";
import { faBarsStaggered } from '@fortawesome/free-solid-svg-icons';
import uWinnLogo from "../../Image/logo192.png";
import { faCircle ,faCircleUser} from '@fortawesome/free-regular-svg-icons';

import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { faHouse , faEyeSlash, faEye , faBellSlash} from '@fortawesome/free-solid-svg-icons';

import {
  getRandomColor,
  createImageFromInitials,
} from "../../controllers/common";
import { ASSETS_BASE_URL } from "../../config/constants";

const { Panel } = Collapse;

const SidebarItem = ({ to, icon, name, isOpen, className }) => (
  <NavLink to={to} className={`link ${className}`} activeClassName="active">
    <div className="icon">{icon}</div>
    <div style={{ display: isOpen ? "block" : "none" }} className="link_text">
      {name}
    </div>
  </NavLink>
);

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const handleCollapseToggle = () => {
    setIsOpen(!isOpen);
  };
  const user = JSON.parse(sessionStorage.getItem("ADMIN-INFO"));
  const [USERDATA, setUserData] = useState(user || "");

  
  const [PERMISSIONDATA, setPermissionData] = useState('');

  useEffect(()=>{
    if(sessionStorage.getItem("ADMIN-PERMISSION") !== 'undefined'){
      const permission = JSON.parse(sessionStorage.getItem("ADMIN-PERMISSION"));
      setPermissionData(permission);
    }
  },[])

  return (
    <div
      className={`container-fluid side_bar_main_div ${
        isOpen ? "sidebar-open" : "sidebar-closed"
      }`}
    >
      <div
        style={{ width: isOpen ? "300px" : "70px", position: "relative " }}
        className="sidebar"
      >
        <div className="top_section">
          <img
            src={SidebarLogo}
            className="sidebar_logo"
            alt="Logo"
            style={{
              display: isOpen ? "block" : "none",
              height: "50px",
              width: "auto",
            }}
          />
          <div
            style={{ marginLeft: isOpen ? "130px" : "0px" }}
            className="bars"
          >
            <FontAwesomeIcon icon={faBarsStaggered} onClick={handleCollapseToggle} />
         
          </div>
        </div>
        <div className="my_sidebar_all_section">
          <SidebarItem
            to="/dashboard"
            icon={<FontAwesomeIcon icon={faHouse} />}
            name="Dashboard"
            isOpen={isOpen}
          />
          {(user?.admin_type === 'Super Admin') && (
            <React.Fragment>
              <Collapse accordion className="my_collapse_icon">
                <Panel
                  header={
                    <React.Fragment>
                         <FontAwesomeIcon icon={faCircleUser} className="sidebar_collapse_iohomeoutline" />
                    
                      <span className={`sidebar_collapse_iohomeoutline_categoires ${isOpen ? "visible" : "hidden"}`}> Accounts </span>
                    </React.Fragment>
                  }
                  key="categories"
                  className={`side_bar_categories ${isOpen ? "arrow-visible" : "arrow-hidden"}`}
                >
                  {user?.admin_type === 'Super Admin' &&(
                    <SidebarItem to="/sub-admin/list"   icon={<FontAwesomeIcon icon={faCircle} />} name="Sub Admin" isOpen={isOpen} />
                  )}
                </Panel>
              </Collapse>
            </React.Fragment>
          )}

           
        {/*{(PERMISSIONDATA?.invoice?.invoice === 'Y' || user?.admin_type === 'Super Admin') && (
            <SidebarItem to="/invoice/list" icon={<MdOutlinePayment />} name="Invoice" isOpen={isOpen} />
          )} */}
            {(PERMISSIONDATA?.customer_due?.customer_list === 'Y' || user?.admin_type === 'Super Admin') && (
              <React.Fragment>
                <Collapse accordion className="my_collapse_icon">
                  <Panel
                    header={
                      <React.Fragment>
                      <FontAwesomeIcon icon={faCreditCard} className="sidebar_collapse_iohomeoutline" />

                        <span className={`sidebar_collapse_iohomeoutline_categoires ${isOpen ? "visible" : "hidden"}`}> Customer's Due </span>
                      </React.Fragment>
                    }
                    key="categories"
                    className={`side_bar_categories ${isOpen ? "arrow-visible" : "arrow-hidden"}`}
                  >
                    {(PERMISSIONDATA?.customer_due?.customer_list === 'Y' || user?.admin_type === 'Super Admin') && (
                      <SidebarItem to="/customers-due/list"   icon={<FontAwesomeIcon icon={faCircle} />} name="Customer's List" isOpen={isOpen} />
                    )}
                  </Panel>
                </Collapse>
              </React.Fragment>
            )}
            
            {(PERMISSIONDATA?.invoice?.individual === 'Y' || PERMISSIONDATA?.invoice?.bulk_request === 'Y' || user?.admin_type === 'Super Admin') && (
              <React.Fragment>
                <Collapse accordion className="my_collapse_icon">
                  <Panel
                    header={
                      <React.Fragment>
                      <FontAwesomeIcon icon={faCreditCard} className="sidebar_collapse_iohomeoutline" />

                        <span className={`sidebar_collapse_iohomeoutline_categoires ${isOpen ? "visible" : "hidden"}`}> Invoice </span>
                      </React.Fragment>
                    }
                    key="categories"
                    className={`side_bar_categories ${isOpen ? "arrow-visible" : "arrow-hidden"}`}
                  >
                    {(PERMISSIONDATA?.invoice?.individual === 'Y' || user?.admin_type === 'Super Admin') &&(
                      <SidebarItem to="/invoice/list"   icon={<FontAwesomeIcon icon={faCircle} />} name="Individual" isOpen={isOpen} />
                    )}
                    {(PERMISSIONDATA?.invoice?.bulk_request === 'Y' || user?.admin_type === 'Super Admin') &&(
                      <SidebarItem to="/invoice/request"    icon={<FontAwesomeIcon icon={faCircle} />}  name="Bulk Request" isOpen={isOpen} />
                    )}
                  </Panel>
                </Collapse>
              </React.Fragment>
            )}
          
          {/* {(PERMISSIONDATA?.notifications?.notifications === 'Y' || user?.admin_type === 'Super Admin') && (
            <SidebarItem to="/notifications"    icon={<FontAwesomeIcon icon={faBellSlash} />}  name="Notifications" isOpen={isOpen} className="custom_profile_class_productList" />
          )} */}
          <React.Fragment>
            <SidebarItem
              icon={
                <img
                  src={
                    user?.admin_image
                      ? ASSETS_BASE_URL + user.admin_image
                      : uWinnLogo
                  }
                  alt=""
                  className="user_profile_pic_sidebar"
                  onClick={handleCollapseToggle}
                  width="100px"
                />
              }
              to='javaScript:void(0)'
              name={
                <div className="sidebar_profile_main_content_section" onClick={()=>navigate("/sub-admin/addeditdata", { state: user })} >
                  <React.Fragment>
                    <div className="sidebar_profile_main_content">
                      <div className="user_profile_pic_Admin_name">
                        <span className="user_profile_pic_Admin_panel">
                          {`${user?.admin_first_name} ${user?.admin_last_name}`}
                        </span>{" "}
                        <br />
                        <span className="user_profile_pic_Admin_panel_">
                          Admin
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                  <React.Fragment>
                  <FontAwesomeIcon icon="fa-regular fa-greater-than"    className="side_bar_fagreaterthan"
                      onClick={handleCollapseToggle} />
                  </React.Fragment>
                </div>
              }
              isOpen={isOpen}
              className="custom_profile_class_productList"
            >
            </SidebarItem>
          </React.Fragment>
        </div>
      </div>
      <main>{children}</main>
    </div>
  );
};

export default Sidebar;
